/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { modules } from '@config';

const applied_store_credit = modules.storecredit.useCommerceModule
    ? `
applied_store_credit {
    applied_balance {
      currency
      value
    }
    current_balance {
      currency
      value
    }
    enabled
}
`
    : `
applied_store_credit {
    store_credit_amount
    is_use_store_credit
}
`;

const applied_cashback = `
applied_cashback {
    data {
        amount
        promo_name
    }
    is_cashback
    total_cashback
}
`;

const applied_coupons = `
applied_coupons {
    code
}
`;

// const applied_extrafee = `
// applied_extra_fee {
//     extrafee_value {
//       currency
//       value
//     }
//     select_options {
//       default
//       label
//       option_id
//       price
//     }
//     show_on_cart
//     title
// }
// addtional_fees {
//     data {
//       enabled
//       fee_name
//       frontend_type
//       id_fee
//       options {
//         default
//         label
//         option_id
//         price
//       }
//     }
//     show_on_cart
// }
// `;

const applied_gift_cards = modules.giftcard.useCommerceModule
    ? `
applied_gift_cards {
    applied_balance {
      currency
      value
    }
    code
    current_balance {
      currency
      value
    }
}
`
    : `
applied_gift_cards {
    giftcard_amount
    giftcard_detail {
        giftcard_amount_used
        giftcard_code
    }
}

`;

const prices = `
prices {
  discounts {
      amount {
          currency
          value
      }
      label
  }
  subtotal_excluding_tax {
      currency
      value
  }
  subtotal_including_tax {
      currency
      value
  }
  applied_taxes {
      amount {
          value
          currency
      }
  }
  grand_total {
      currency
      value
  }
}
`;

const customizable_options = `
customizable_options {
  id
  label
  is_required
  sort_order
  values {
    label
    value
    price {
      type
      units
      value
    }
  }
}
`;

const itemsCart = `
items {
  id
  quantity
  stock_information
  ... on SimpleCartItem {
    SimpleMiniCustomizable: ${customizable_options}
  }

  ... on VirtualCartItem {
    virtualMiniCustomizable: ${customizable_options}
  }
  ... on ConfigurableCartItem {
      ConfigurableMiniCustomizable: ${customizable_options}
      configurable_options {
      option_label
      value_label
    }
  }
  ... on BundleCartItem {
    bundle_options {
      label
      type
      values {
        label
        price
        quantity
      }
    }
  }
  ... on DownloadableCartItem {
    downloadableMiniCustomizable: ${customizable_options}
    links {
      title
    }
  }
  custom_price{
    row_total{
      currency
      value
    }
  }
  product {
    id
    name
    small_image {
      url
      label
    }
    url_key
    sku
    stock_status
    categories {
      name
    }
  }
}
`;

const itemCrosssell = `
  items {
    product {
      crosssell_products {
        id
        name
        url_key
        sku
        thumbnail {
          url
        }
        small_image {
          url,
          label
        }
        price_tiers {
          discount {
            percent_off
            amount_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        price_range {
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
      }
    }
  }
`;

const cartRequiredSelection = `
id
errorItems
total_quantity
${modules.checkout.cashback.enabled ? applied_cashback : ''}
${modules.promo.enabled ? applied_coupons : ''}
${modules.storecredit.enabled ? applied_store_credit : ''}
${prices}
`;

const cartRequiredSelectionItem = `
id
errorItems
total_quantity
${itemsCart}
`;

const cartCrosssellItem = `
id
errorItems
total_quantity
${itemCrosssell}
`;

const tempCartItem = `
  items {
    additional_info {
      label
      value
    }
    id
    active_item_id
    is_selected_to_checkout
    prices {
      price {
        value
        currency
      }
      row_total {
        value
        currency
      }
    }
    product {
      product_id
      vendor_name
      vendor_code
      image
      name
      sku
      url_key
    }
    quantity
    stock_status
    stock_information
  }
`;

export const getCart = gql`
    query getCartData($cartId: String!) {
        cart(cart_id: $cartId) {
            ${applied_gift_cards}
            ${cartRequiredSelection}
            checkout_promo {
              checkout_description
              vendor {
                vendor_code
              }
            }
        }
    }
`;

export const getCartItem = gql`
    query getCartItem($cartId: String!) {
        cart(cart_id: $cartId) {
          ${cartRequiredSelectionItem}
        }
    }
`;

export const getTempCartItem = gql`
    query getTempCartItem($cartId: String!) {
        tempCart(cart_id: $cartId) {
          active_quote_id
          email
          id
          total_quantity
          ${tempCartItem}
        }
    }
`;

export const getMiniCart = gql`
    query getCartData($cartId: String!) {
        cart(cart_id: $cartId) {
          id
          errorItems
          total_quantity
          items {
            id
            quantity
            stock_information
            ... on SimpleCartItem {
              SimpleMiniCustomizable: ${customizable_options}
            }

            ... on VirtualCartItem {
              virutalItemCustomizable: ${customizable_options}
            }

            ... on ConfigurableCartItem {
                ConfigurableMiniCustomizable: ${customizable_options}
                configurable_options {
                option_label
                value_label
              }
            }
            ... on BundleCartItem {
              bundle_options {
                label
                type
                values {
                  label
                  price
                  quantity
                }
              }
            }
            ... on DownloadableCartItem {
              downloadablItemCustomizable: ${customizable_options}
              links {
                title
              }
            }
            custom_price{
              row_total{
                currency
                value
              }
            }
            product {
              id
              name
              small_image {
                url
                label
              }
              url_key
              sku
              stock_status
            }
          }
        }
    }
`;

export const deleteCartitem = gql`
    mutation deleteCartItem($cartId: String!, $cart_item_id: Int!) {
      removeItemFromCart(
        input: { cart_id: $cartId, cart_item_id: $cart_item_id }
      ) {
        cart {
          id
          total_quantity
        }
      }
    }
`;

export const deleteTempCartItem = gql`
    mutation deleteTempCartItem($cartId: String!, $is_checkout: Boolean, $cartItems: [RemoveTempCartItemsInput!]!) {
      removeItemFromTempCart(
        cartId: $cartId,
        is_checkout: $is_checkout,
        cartItems: $cartItems,
      ) {
        tempCart {
          total_quantity
          subtotal_including_tax{
            value
            currency
          }
          grand_total{
            value
            currency
          }
          ${tempCartItem}
        }
      }
    }
`;

export const deleteTempCartItemBulk = gql`
    mutation deleteTempCartItem($cartId: String!, $quoteId: String!, $is_checkout: Boolean, $cartItems: [RemoveTempCartItemsInput!]!) {
      removeItemFromTempCart(
        cartId: $cartId,
        quoteId: $quoteId,
        is_checkout: $is_checkout,
        cartItems: $cartItems,
      ) {
        tempCart {
          id
          active_quote_id
          total_quantity
        }
      }
    }
`;

export const unselectItemFromTempCart = gql`
    mutation unselectItemFromTempCart($cartId: String!, $quoteId: String!, $cartItems: [RemoveTempCartItemsInput!]!) {
      unselectItemFromTempCart(
        cartId: $cartId,
        quoteId: $quoteId,
        cartItems: $cartItems,
      ) {
        tempCart {
          id
          active_quote_id
          total_quantity
        }
      }
    }
`;

// eslint-disable-next-line no-unused-vars
export const getCrossellCart = (config = {}) => gql`
  query getCartData($cartId: String!) {
    cart(cart_id: $cartId) {
      ${cartCrosssellItem}
    }
  }
`;

export const deleteMiniCartitem = gql`
    mutation deleteCartItem($cartId: String!, $cart_item_id: Int!) {
      removeItemFromCart(
        input: { cart_id: $cartId, cart_item_id: $cart_item_id }
      ) {
        cart {
          id
          total_quantity
          items {
            id
            quantity
            stock_information
            ... on SimpleCartItem {
              SimpleMiniCustomizable: ${customizable_options}
            }

            ... on VirtualCartItem {
              virutalItemCustomizable: ${customizable_options}
            }

            ... on ConfigurableCartItem {
                ConfigurableMiniCustomizable: ${customizable_options}
                configurable_options {
                option_label
                value_label
              }
            }
            ... on BundleCartItem {
              bundle_options {
                label
                type
                values {
                  label
                  price
                  quantity
                }
              }
            }
            ... on DownloadableCartItem {
              downloadablItemCustomizable: ${customizable_options}
              links {
                title
              }
            }
            custom_price{
              row_total{
                currency
                value
              }
            }
            product {
              id
              name
              small_image {
                url
                label
              }
              url_key
              sku
              stock_status
            }
          }
        }
      }
    }
`;

export const updateCartitem = gql`
    mutation updateCartItems($cartId: String!, $cart_item_id: Int!, $quantity: Float!) {
      updateCartItems(
        input: { 
          cart_id: $cartId,
          cart_items: {cart_item_id: $cart_item_id, quantity: $quantity }
        }
      ) {
        cart {
          id
          errorItems
          total_quantity
        }
      }
    }
`;

export const updateTempCartItem = gql`
    mutation updateTempCartItems($cartId: String!, $cartItems: [UpdateTempCartItemsInput!]!) {
      updateTempCartItems(
        cartId: $cartId,
        cartItems: $cartItems,
      ) {
        tempCart {
          total_quantity
          subtotal_including_tax{
            value
            currency
          }
          grand_total{
            value
            currency
          }
          ${tempCartItem}
        }
      }
    }
`;

export const updateMiniCartitem = gql`
    mutation updateCartItems($cartId: String!, $cart_item_id: Int!, $quantity: Float!) {
      updateCartItems(
        input: { 
          cart_id: $cartId,
          cart_items: {cart_item_id: $cart_item_id, quantity: $quantity }
        }
      ) {
        cart {
          id
          errorItems
          total_quantity
          items {
            id
            quantity
            stock_information
            ... on SimpleCartItem {
              SimpleMiniCustomizable: ${customizable_options}
            }

            ... on VirtualCartItem {
              virutalItemCustomizable: ${customizable_options}
            }

            ... on ConfigurableCartItem {
                ConfigurableMiniCustomizable: ${customizable_options}
                configurable_options {
                option_label
                value_label
              }
            }
            ... on BundleCartItem {
              bundle_options {
                label
                type
                values {
                  label
                  price
                  quantity
                }
              }
            }
            ... on DownloadableCartItem {
              downloadablItemCustomizable: ${customizable_options}
              links {
                title
              }
            }
            custom_price{
              row_total{
                currency
                value
              }
            }
            product {
              id
              name
              small_image {
                url
                label
              }
              url_key
              sku
              stock_status
            }
          }
        }
      }
    }
`;

export const addWishlist = gql`
    mutation addWishlist($productId: Int!) {
        addProductToWishlist(productId: $productId) {
            info
        }
    }
`;

export const getCartIdUser = gql`
    {
        customerCart {
            id
        }
    }
`;

export const getTempCartActiveQuote = gql`
    query getTempCartActiveQuote($cartId: String!) {
        tempCart(cart_id: $cartId) {
          id
          active_quote_id
        }
    }
`;

export const getTempCartIdUser = gql`
    {
        customerTempCart {
            id
            active_quote_id
        }
    }
`;

export const getCountCart = gql`
    query getCartData($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            total_quantity
        }
    }
`;

export const getCountTempCart = gql`
    query getTempCartData($cartId: String!) {
        tempCart(cart_id: $cartId) {
            id
            active_quote_id
            total_quantity
        }
    }
`;

// reorder
export const reOrder = gql`
mutation reOrder($order_id: String!) {
  reorder(input: {order_id: $order_id}) {
    cart_id
  }
}
`;

export const applyCouponToCart = gql`
    mutation($cartId: String!, $coupon: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $coupon }) {
            cart {
                id
            }
        }
    }
`;

export const applyCouponToCartGuest = gql`
    mutation($cartId: String!, $coupon: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $coupon }) {
            cart {
                id
            }
        }
    }
`;

export const removeCouponFromCart = gql`
    mutation($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
            cart {
                id
            }
        }
    }
`;

export const removeCouponFromCartGuest = gql`
    mutation($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
            cart {
                id
            }
        }
    }
`;

export const addSimpleProductsToCart = gql`
  mutation addSimpleProductsToCart(
      $cartId: String!,
      $qty: Float!,
      $sku: String!,
      ${modules.product.customizableOptions.enabled
        && `
        $customizable_options: [CustomizableOptionInput],
        $entered_options: [EnteredOptionInput] 
      `}
  ) {
    addSimpleProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
          && ' customizable_options: $customizable_options'}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
            && ' entered_options: $entered_options'}
        }
      }
    }) {
      cart {
        id
        total_quantity
      }
    }
  }
`;

export const clearMagentoCart = gql`
    mutation clearMagentoCart($cartId: String!) {
      clearMagentoCart(
        cartId: $cartId,
      ) {
        tempCart {
          id
          active_quote_id
          total_quantity
          ${tempCartItem}
        }
      }
    }
`;

export const selectItemToTempCartSchema = gql`
  mutation selectItemToTempCart($cartId: String!, $cartItems: [TempCartItemsInput!]!) {
    selectItemToTempCart(
      cartId: $cartId
      cartItems: $cartItems
    ) {
      tempCart {
        grand_total {
          currency
          value
        }
        subtotal_including_tax {
          currency
          value
        } 
      }
    }
  }
`;

export const unselectItemToTempCartSchema = gql`
  mutation unselectItemToTempCart($cartId: String!, $cartItems: [RemoveTempCartItemsInput!]!) {
    unselectItemToTempCart(
      cartId: $cartId
      cartItems: $cartItems
    ) {
      tempCart { 
        grand_total {
          currency
          value
        }
        subtotal_including_tax {
          currency
          value
        } 
      }
    }
  }
`;
