/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import { useTranslation } from '@i18n';
import dynamic from 'next/dynamic';

const SelectLanguage = dynamic(() => import('@core_modules/theme/components/header/desktop/components/selectlanguage'), { ssr: false });

const ViewTop = () => {
    const { t } = useTranslation(['common']);
    return (
        <header className="top-header-container">
            <ul className="top-header">
                <li className="top-header-left">
                    {/* <Link href="/">
                        <a className="top-header-item">
                            <div className="top-header-item-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.794" height="29.608" viewBox="0 0 17.794 29.608">
                                    <g id="Group_26" data-name="Group 26" transform="translate(-51.795 -8.933)">
                                        <path id="Path_26" data-name="Path 26" d="M54.33,38.541a3.741,3.741,0,0,1-1.4-.647A2.994,2.994,0,0,1,51.8,35.618c-.008-.174,0-.347,0-.521q0-11.506,0-23.013A3.024,3.024,0,0,1,53.03,9.509a2.648,2.648,0,0,1,1.6-.57q6.059-.008,12.116,0a2.974,2.974,0,0,1,2.834,2.884c0,.077,0,.154,0,.231q0,11.68,0,23.36a2.92,2.92,0,0,1-1.26,2.556,7.45,7.45,0,0,1-1.275.57ZM53.288,23.708q0,5.854,0,11.708a1.485,1.485,0,0,0,1.6,1.631q5.8,0,11.595,0a1.482,1.482,0,0,0,1.606-1.6q0-11.709,0-23.417a1.483,1.483,0,0,0-1.605-1.6q-5.8,0-11.595,0a1.916,1.916,0,0,0-.485.048,1.478,1.478,0,0,0-1.121,1.581Q53.286,17.883,53.288,23.708Z" fill="#fff" />
                                        <path id="Path_27" data-name="Path 27" d="M60.649,36.069a1.48,1.48,0,1,1,1.531-1.441A1.494,1.494,0,0,1,60.649,36.069Z" fill="#fff" />
                                        <path id="Path_28" data-name="Path 28" d="M61.936,12.883c-.472,0-.944.007-1.415,0A.744.744,0,1,1,60.5,11.4q1.43-.017,2.859,0a.743.743,0,1,1-.011,1.481C62.879,12.891,62.408,12.883,61.936,12.883Z" fill="#fff" />
                                        <path id="Path_29" data-name="Path 29" d="M58.721,12.135a.745.745,0,0,1-1.49,0,.767.767,0,0,1,.748-.729A.756.756,0,0,1,58.721,12.135Z" fill="#fff" />
                                    </g>
                                </svg>
                            </div>
                            <div className="top-header-item-title">
                                {t('common:header:downloadApp')}
                            </div>
                        </a>
                    </Link> */}
                </li>
                <li className="top-header-right">
                    <Link href="/about-us">
                        <a className="top-header-item">
                            <div className="top-header-item-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <g id="Group_27" data-name="Group 27" transform="translate(-670.994 -16.271)">
                                        <path id="Path_33" data-name="Path 33" d="M679.558,32.271h-1.093a.891.891,0,0,0-.137-.029,7.665,7.665,0,0,1-2.034-.441,7.993,7.993,0,0,1-5.192-8.834,7.716,7.716,0,0,1,2.465-4.567,7.81,7.81,0,0,1,6.734-2.02,7.716,7.716,0,0,1,4.639,2.545,7.794,7.794,0,0,1,1.93,3.954c.048.275.083.553.123.829v1.125c-.01.044-.024.088-.03.133-.036.263-.055.528-.105.787a7.923,7.923,0,0,1-2.566,4.51,7.83,7.83,0,0,1-4.09,1.913C679.987,32.211,679.773,32.24,679.558,32.271ZM679,17.611a6.66,6.66,0,1,0,6.654,6.649A6.653,6.653,0,0,0,679,17.611Z" transform="translate(0 0)" fill="#fff" />
                                        <path id="Path_34" data-name="Path 34" d="M679.423,25.416c0,.864,0,1.728,0,2.593a.664.664,0,0,1-.771.722.65.65,0,0,1-.554-.554,1.587,1.587,0,0,1-.011-.186q0-2.586,0-5.17a.672.672,0,0,1,.493-.724.655.655,0,0,1,.831.555c.02.212.012.426.012.639Q679.424,24.353,679.423,25.416Z" transform="translate(0.242 0.198)" fill="#fff" />
                                        <path id="Path_35" data-name="Path 35" d="M679.42,20.157a.667.667,0,1,1-.663-.663A.663.663,0,0,1,679.42,20.157Z" transform="translate(0.242 0.11)" fill="#fff" />
                                    </g>
                                </svg>
                            </div>
                            <div className="top-header-item-title">
                                {t('common:header:about')}
                            </div>
                        </a>
                    </Link>
                    <div className="top-header-item">
                        <div className="top-header-item-icon">
                            <SelectLanguage />
                        </div>
                    </div>
                </li>
            </ul>
            <style jsx>
                {`
                    header.top-header-container {
                        color: #fff;
                    }
                    ul.top-header {
                        max-width: 1440px;
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        list-style: none;
                        padding: 12px 18px;
                    }
                    li.top-header-right, li.top-header-left {
                        font-size: 12px;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                    }
                    .top-header-item > * + * {
                        margin-left: 6px;
                    }
                    .top-header-item {
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                    }
                    .top-header-item + .top-header-item {
                        margin-left: 24px;
                    }
                    .top-header-item:hover > .top-header-item-title {
                        text-decoration: underline;
                    }
                    .top-header-item-icon {
                        margin-top: 4px;
                    }
                `}
            </style>
        </header>
    );
};
export default ViewTop;
