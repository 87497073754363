/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */
const useMagentoCommerce = true; // setup uses magento commerce or community
const storeCode = ''; // fill it with any store code when the magento is setup with multiple stores. leave it empty to use default store.
const assetsVersion = '1.0.5';

const HOST = {
    local: 'http://localhost:3000',
    dev: 'https://9to9-pwa.testingnow.me',
    stage: 'https://9to9-pwa.huawei-staging.testingnow.me',
    prod: 'https://9to9.co.id',
};

/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://9to9.testingnow.me/graphql',
    dev: 'https://9to9.testingnow.me/graphql',
    stage: 'https://9to9.huawei-staging.testingnow.me/graphql',
    prod: 'https://magento.9to9.co.id/graphql',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */

const installMessage = 'Get our free app.';
const appName = 'Swift APP';
const originName = 'pwa';

/* Social Sharing */
const shareIcon = {
    facebook: true,
    twitter: true,
    line: true,
    email: true,
    telegram: true,
    pinterest: false,
    linkedin: false,
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const translation = {
    defaultLanguage: 'id', // just change to your default language
    languages: ['id', 'en'], // array code language what you want
    // language label code
    languagesLabel: {
        id: 'Indonesia',
        en: 'English',
    },
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: true,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: 'GTM-MW9SN9D', // sample: GTM-N76V8KQ
        stage: '', // sample: GTM-N76V8KQ
        prod: 'GTM-WTPFN2J', // sample: GTM-N76V8KQ
    },
};

/* Recapthca Configuration */
const recaptcha = {
    enable: true,
    siteKey: {
        local: '6Lf1l0QjAAAAAKldH2IlVJLENfYw7TrRehP_pQMK', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        dev: '6LfrWUkjAAAAAJ0zIgkuZaK7rayyOQWsWIWYlGde', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        stage: '6Lf3WUkjAAAAAFZCD197SB0yiX18APbSzmM_zN7t', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        prod: '6Le8_EAjAAAAAIMB5ygmCZ9VlM-vXhT9TOfSxprb', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
    },
    serverKey: {
        local: '6Lf1l0QjAAAAADzAgrElRzRuUExZ4FGJt79VAhJ5', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        dev: '6LfrWUkjAAAAACItDow13ZMJc6HjbJasrOCZv-7l', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        stage: '6Lf3WUkjAAAAAILKHCZA-Q_sH70iOkqRO61uPk3f', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        prod: '6Le8_EAjAAAAAIDIt7BrTt1gGSZzGeBIwRY9n2MO', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
    },
};

// error management monitoring
const sentry = {
    enabled: false,
    enableMode: 'production',
    dsn: {
        local: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
        dev: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
        stage: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
        prod: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
    },
};

const rollbar = {
    enabled: false,
    config: {
        accessToken: '76876f52664341b4a1981c4618723bda',
        captureUncaught: true,
        captureUnhandledRejections: true,
    },
};

/* Contact Us */
// identifiers for cmsBlocks in contact page
const cmsContactIdentifiers = 'weltpixel_contact_page';

/* Dashboard */
// identifiers for cmsBlocks in contact page
const cmsSocialMediaLinkIdentifiers = 'pwa_socmed_links';

/* Social media link */
// social media link in dashboard
const enableSocialMediaLink = true;

/* Loader */
const loaderImage = '/assets/img/loader.svg';

/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */

const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const nameTempCartId = 'ntci';
const nameCheckoutState = 'ncs';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
const expiredToken = 9999999999;
const expiredDefault = 365;
const localResolverKey = 'resolver';

const features = {
    useCustomStyle: true,
    ssrCache: false,
    crm: {
        enabled: false,
        graphqlEndpoint: {
            local: 'http://swiftcrm.testingnow.me/graphql',
            dev: 'http://swiftcrm.testingnow.me/graphql',
            stage: 'http://swiftcrm.testingnow.me/graphql',
            prod: 'http://swiftcrm.testingnow.me/graphql',
        },
    },
    facebookMetaId: {
        enabled: false,
        app_id: '', // if enabled add fb app id here. e.g. 3080154482073095
    },
    // masuk module -> pindah jika module sudah siap
    productAvailableToCart: {
        SimpleProduct: true,
        ConfigurableProduct: true,
        VirtualProduct: true,
        GroupedProduct: true,
        BundleProduct: true,
        DownloadableProduct: false,
    },
    imageSize: {
        product: {
            width: 309,
            height: 324,
        },
        homeSlider: {
            mobile: {
                width: 732,
                height: 346,
            },
            desktop: {
                width: 1404,
                height: 336,
            },
        },
        category: {
            width: 960,
            height: 577,
        },
    },
    vesMenu: {
        enabled: true,
        alias: 'top-menu-pwa',
    },
    customInstallApp: {
        enabled: false,
    },
    firebase: {
        config: {
            apiKey: 'AIzaSyBEyogF4CVcoAh9erbjkel9kW0_57kyWYI',
            authDomain: 'to9-c8535.firebaseapp.com',
            databaseURL: 'https://to9-c8535-default-rtdb.asia-southeast1.firebasedatabase.app',
            projectId: 'to9-c8535',
            storageBucket: 'to9-c8535.appspot.com',
            messagingSenderId: '540031233965',
            appId: '1:540031233965:web:8bed99e91ac6a06d65bb24',
            measurementId: 'G-C7X081YNTZ',
        },
        pushNotification: {
            enabled: true,
            config: {
                // key from cloud messaging sertificat web push
                pairKey: 'BO9ldIOrC2HSIFW8ITY2hmGNkDW8paNjpB1BdSwkyeoaXbbQgQIGZXv5qit4F1tY9AJIhgT7Y-Oz-axPEzE03-o',
            },
        },
    },
    thumbor: {
        enable: true,
        useHttpsOrHttp: false,
        url: 'https://thumbor.sirclocdn.com/unsafe/widthxheight/filters:format(webp)/',
    },
    amoebaMiddleware: {
        enabled: true,
        username: 'CA128C5E81583',
        password: 'MgUh5vIDi2',
        endPoint: {
            local: 'https://amoeba.9to9.testingnow.me/graphql',
            dev: 'https://amoeba.9to9.testingnow.me/graphql',
            stage: 'https://9to9-amoeba.huawei-staging.testingnow.me/graphql',
            prod: 'https://amoeba.9to9.co.id/graphql',
        },
    },
};

const modules = {
    product: {
        customizableOptions: {
            enabled: true,
            availableOptions: {
                CustomizableAreaOption: true,
                CustomizableDateOption: true,
                CustomizableDropDownOption: true,
                CustomizableMultipleOption: true,
                CustomizableFieldOption: true,
                CustomizableFileOption: false,
                CustomizableRadioOption: true,
                CustomizableCheckboxOption: true,
            },
        },
    },
    authentication: {
        enabled: true,
        path: '/authentication',
    },
    about: {
        enabled: true,
        path: '/about-us',
    },
    blog: {
        enabled: true,
        path: '/blog',
        link: {
            detail: {
                href: '/blog/[id]',
                as: '/blog/',
            },
            category: {
                href: '/blog/category/[id]',
                as: '/blog/category/',
            },
            default: {
                href: '/blog',
            },
        },
        featuredImage: true,
    },
    brands: {
        enabled: true,
        path: '/brands',
    },
    catalog: {
        enabled: true,
        productListing: {
            pageSize: 8,
            drawerFilterOnDesktop: {
                enabled: false, // used if need to desktop view on large screen
            },
            label: {
                enabled: true,
                new: {
                    enabled: false,
                },
                sale: {
                    enabled: false,
                },
            },
            configurableOptions: {
                enabled: false,
            },
            rating: {
                enabled: true,
            },
            addToCart: {
                enabled: true,
            },
        },
        pdp: {
            popupDetailImage: {
                enabled: true,
            },
        },
    },
    confirmpayment: {
        enabled: true,
        path: '/confirmpayment',
    },
    checkout: {
        enabled: true,
        checkoutOnly: false,
        path: '/checkout',
        ipayUrl: 'ipay88/ipayredirect/?orderId=',
        snapUrl: {
            dev: 'https://app.sandbox.midtrans.com/snap/snap.js',
            prod: 'https://app.midtrans.com/snap/snap.js',
        },
        atomeUrl: 'atome/pwa/redirect/cart_id/',
        pickupStore: {
            enabled: false,
        },
        extraFee: {
            enabled: true,
        },
        cashback: {
            enabled: true,
        },
        orderComment: {
            enabled: false,
        },
    },
    cart: {
        enabled: true,
        path: '/checkout/cart',
    },
    customer: {
        enabled: true,
        path: '/customer',
        plugin: {
            address: {
                splitCity: true,
            },
            newsletter: {
                enabled: true,
            },
        },
    },
    contact: {
        enabled: true,
        path: '/contact',
        recaptcha: {
            enabled: true,
        },
    },
    forgotpassword: {
        enabled: true,
        path: '/customer/account/forgotpassword',
        recaptcha: {
            enabled: true,
        },
    },
    rewardpoint: {
        enabled: true,
        path: '/aw_rewardpoints/info',
    },
    rma: {
        enabled: true,
        path: '/rma/customer',
    },
    storecredit: {
        enabled: true,
        path: '/customer/account/storecredit',
        useCommerceModule: true,
    },
    storeLocator: {
        enabled: false,
        path: '/storelocator',
    },
    giftcard: {
        enabled: true,
        path: '/awgiftcard/card',
        useCommerceModule: true,
    },
    login: {
        enabled: true,
        path: '/customer/account/login',
        recaptcha: {
            enabled: true,
        },
    },
    notification: {
        enabled: false,
        path: 'inboxnotification/notification',
    },
    register: {
        enabled: true,
        path: '/customer/account/create',
        recaptcha: {
            enabled: true,
        },
    },
    trackingorder: {
        enabled: true,
        path: '/sales/order/track',
        fieldDetail: {
            shipperid: ['name', 'description', 'updateDate'],
            gosend: [
                'bookingType',
                'buyerAddressName',
                'buyerAddressDetail',
                'driverId',
                'driverName',
                'insuranceDetails',
                'liveTrackingUrl',
                'receiverName',
                'sellerAddressDetail',
                'sellerAddressName',
                'status',
                'cancelDescription',
                'orderArrivalTime',
                'orderClosedTime',
                'orderCreatedTime',
            ],
        },
    },
    thanks: {
        enabled: true,
        path: '/checkout/onepage/success',
    },
    home: {
        enabled: true,
        useCmsPage: {
            enable: true,
            identifier: 'homepage-pwa',
        },
        featuresProduct: {
            enable: true,
            url_key: 'homepage-featured-products',
        },
        categoryList: {
            enable: true,
            url_key: 'homepage-featured-categories',
            imageSize: {
                mobile: {
                    width: 960,
                    height: 577,
                },
                desktop: {
                    width: 404,
                    height: 465,
                },
            },
        },
        bannerSlider: {
            enable: true,
            title: 'Client App Homepage Slider',
        },
    },
    promo: {
        enabled: true,
    },
    order: {
        enabled: true,
        path: '/sales/order',
    },
    wishlist: {
        enabled: true,
        path: '/wishlist',
    },
    maintenance: {
        enabled: true,
        path: '/maintenance',
    },
    setting: {
        enabled: true,
        path: '/setting',
    },
    error: {
        enabled: true,
    },
};

const nossrCache = [
    '/aw_rewardpoints/info',
    '/sales/order/history',
    '/customer/account/profile',
    '/customer/account/address',
    '/awgiftcard/card',
    '/customer/account/storecredit',
    '/inboxnotification/notification',
    '/customer/newsletter',
    '/rma/customer',
    '/confirmpayment',
    '/checkout',
    '/checkout/cart',
    '/graphql',
    '/authentication',
    '/checkout/onepage/success',
];

const debuging = {
    originalError: false,
    pwaCheckoutLog: true,
};

const general = {
    defaultCurrencyCode: 'IDR',
};

module.exports = {
    assetsVersion,
    general,
    sentry,
    storeCode,
    debuging,
    GTM,
    HOST,
    graphqlEndpoint,
    shareIcon,
    passwordStrength,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameTempCartId,
    nameCheckoutState,
    nameToken,
    expiredToken,
    expiredDefault,
    loaderImage,
    cmsContactIdentifiers,
    cmsSocialMediaLinkIdentifiers,
    custDataNameCookie,
    nameCheckoutCookie,
    nameGlobalCookie,
    enableSocialMediaLink,
    features,
    nossrCache,
    recaptcha,
    modules,
    installMessage,
    appName,
    localResolverKey,
    originName,
    useMagentoCommerce,
    rollbar,
    translation,
};
