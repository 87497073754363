import { HOST, graphqlEndpoint } from '@config';

const getAppEnv = () => {
    if (typeof window !== 'undefined') {
        return window.APP_ENV;
    }
    return process.env.APP_ENV;
};

export const getHostUrl = () => {
    const appEnv = getAppEnv();
    return HOST[appEnv];
};

export const getEndpoint = () => {
    const appEnv = getAppEnv();
    if (appEnv === 'local') {
        return graphqlEndpoint.dev;
    }
    return graphqlEndpoint[appEnv];
};

export default { getHostUrl, getEndpoint };
