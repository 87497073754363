/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { modules } from '@config';
import { useTranslation } from '@i18n';
import dynamic from 'next/dynamic';

const View = dynamic(() => import('@plugin_productitem/components/Detail/view'), { ssr: false });

const Detail = (props) => {
    const {
        additional_data, enableRating, enableWishlist, feed, ...other
    } = props;

    const { t } = useTranslation(['catalog']);
    const showWishlist = typeof enableWishlist !== 'undefined' ? enableWishlist : modules.wishlist.enabled;
    const showRating = typeof enableRating !== 'undefined' ? enableRating : modules.catalog.productListing.rating;
    const sold = additional_data && additional_data.sold_qty;
    const label = additional_data && additional_data.label;

    return (
        <View
            t={t}
            feed={feed}
            showWishlist={showWishlist}
            showRating={showRating}
            sold={sold}
            label={label}
            {...other}
        />
    );
};

export default Detail;
