import { gql } from '@apollo/client';

/**
 * scema dynamic category
 * @param variables Object {id: number, productSize: Number}
 * @returns grapql query
 */

export const getCategory = (
    variables = {
        productSize: 10,
    },
) => gql`
    {
        categoryList(filters: { ids: { eq: "${variables.id}" } }) {
          id
          name
          is_virtual_category
          virtual_rule
          description
          url_path
          image
          image_path
          breadcrumbs {
            category_id
            category_name
            category_url_path
          }
          children {
            id
            name
            image
            url_path
          }
          cms_block {
            content
            identifier
            title
          } 
          display_mode
          meta_description
          meta_keywords
          meta_title
        }
    }
    `;

/**
 * scema dynamic get attribute filter
 * @param category_id number
 * @returns grapql query
 */

export const getFilter = (catID) => gql`
    {
        getFilterAttributeOptions (catid:${catID}) {
            code
            data {
                field
                id
                label
                maxprice
                minprice
                value {
                    label
                    value
                }
            }
            message
            status
        }
    }
`;

/**
 * get category products
 * @returns {object} gql
 */
export const getCategoryProducts = (variables) => gql`{
    categoryList(filters: { ids: { eq: "${variables.category_id}" } }) {
        url_path
        canonical_url
        meta_description
        meta_keywords
        meta_title
        products(currentPage: ${variables.page}, pageSize: ${variables.products_count}){
            page_info {
                current_page
                page_size
                total_pages
            }
            items {
                id
                sku
                name
                url_key
                categories {
                    name
                }
                __typename
                price_tiers {
                    discount {
                    percent_off
                    amount_off
                    }
                    final_price {
                    currency
                    value
                    }
                    quantity
                }
                price_range {
                    maximum_price {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                    }
                    minimum_price {
                    discount {
                        amount_off
                        percent_off
                    }
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                    }
                }
                special_from_date
                special_to_date      
                max_discount {
                  amount_off
                  percent_off
                }
                new_from_date
                new_to_date
                image {
                    url
                    label
                }
                small_image {
                    url
                    label
                }
                additional_data {
                  sold_qty
                  label
                }
                weltpixel_labels {
                    categoryLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_padding
                        text_bg_color
                        text_font_size
                        text_font_color
                        __typename
                    }
                    productLabel {
                        css
                        customer_group
                        image
                        page_position
                        position
                        priority
                        text
                        text_padding
                        text_bg_color
                        text_font_size
                        text_font_color
                        __typename
                    }
                    __typename
                }
                ... on ConfigurableProduct {
                    variants {
                        product {
                            image{
                            url
                            }
                        }
                        attributes {
                            label
                            code
                            value_index
                        }
                    }
                }
            }
        }
    }
}`;

export const getAmoebaCategoryProducts = gql`
    query getAmoebaProducts(
        $category_id: String
        $productSize: Int
        $productCount: Int
    ) {
        categories(
            withProduct: {
              enabled: true
              data_product_from: $productSize
              data_product_size: $productCount
              data_filter: {
                is_in_stock: true
              }
            }
            filter: {
              url_key: ""
              category_id: $category_id
            }
        ) {
            id
            name
            is_active
            url_key
            products {
                id
                type_id
                name
                url_key
                sku
                image
                description
                short_description
                price_discount
                price {
                    price
                    original_price
                    is_discount
                    customer_group_id
                    tax_class_id
                    final_price
                    min_price
                    max_price
                }
                category {
                    category_id
                    is_virtual
                    is_parent
                    name
                }
            }
        }
    }
`;

export const getCategoryEvent = (
    variables = {
        productSize: 10,
    },
) => gql`
    {
        categoryList(filters: { ids: { eq: "${variables.id}" } }) {
          id
          meta_description
          meta_keywords
          meta_title
          event {
            event_id
            start_date
            end_date
            status
          }
        }
    }
    `;
