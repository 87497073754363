/* eslint-disable no-trailing-spaces */
import { useState, useEffect } from 'react';
import classNames from 'classnames';

const useTimer = (endTime) => {
    const [timeLeft, setTimeLeft] = useState(endTime - Date.now());
    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(timeLeft - 1000);
        }, 1000);
    });

    return [{
        hours: Math.floor((timeLeft / (1000 * 60 * 60))),
        minutes: Math.floor((timeLeft / 1000 / 60) % 60),
        seconds: Math.floor((timeLeft / 1000) % 60),
    }];
};

const FlashSaleTimer = (props) => {
    const { flashSaleEnd, parentId } = props;
    const endFlashSaleDate = new Date(flashSaleEnd).getTime();
    const isFlashSale = Date.now() < endFlashSaleDate;
    const [time] = useTimer(endFlashSaleDate);
    const getTextTime = (number) => (number > 9 ? number : (`0${number || 0}`));
    useEffect(() => {
        if (!isFlashSale) {
            const flashSaleParent = document.getElementById(parentId);
            flashSaleParent.style.display = 'none';
        }
    }, [isFlashSale]);

    return (
        <>
            {
                isFlashSale && (
                    <>
                        <div
                            className={classNames(
                                (time && time.hours && time.hours > 99) ? 'pwa-flash-sale-hundred-hours' : '',
                                'pwa-flash-sale-hours avenir-black',
                            )}
                        >
                            {getTextTime(time && time.hours)}
                        </div>
                        <div className="pwa-flash-sale-minutes avenir-black">{getTextTime(time && time.minutes)}</div>
                        <div className="pwa-flash-sale-seconds avenir-black">{getTextTime(time && time.seconds)}</div>
                    </>
                )
            }
        </>
    );
};

export default FlashSaleTimer;
