import Layout from '@layout';
import { getHost } from '@helper_config';
import {
    getCartId,
    setCartId,
    getTempCartId,
    setTempCartId,
} from '@helper_cartid';
import { clearMagentoCart as mutationClearMagentoCart } from '@core_modules/cart/services/graphql';
import { getTempCartActiveQuote } from '@core_modules/cart/services/graphql/schema';
import { useApolloClient } from '@apollo/client';
import { getLoginInfo } from '@helper_auth';

const HomeCore = (props) => {
    const {
        Content, pageConfig, storeConfig, ...other
    } = props;

    const schemaOrg = [
        {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `${getHost()}/`,
            logo: `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`,
        },
        {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: `${getHost()}/`,
            potentialAction: [
                {
                    '@type': 'SearchAction',
                    target: `${getHost()}/catalogsearch/result?q={search_term_string}`,
                    'query-input': 'required name=search_term_string',
                },
            ],
        },
    ];

    const config = {
        title: storeConfig.default_title,
        header: false, // available values: "absolute", "relative", false (default)
        bottomNav: 'home',
        pageType: 'home',
        schemaOrg,
        ...pageConfig,
    };

    const client = useApolloClient();

    const [clearMagentoCart] = mutationClearMagentoCart();

    React.useEffect(async () => {
    // eslint-disable-next-line no-unused-vars
        let cartId = getCartId();
        let tempCartId = getTempCartId();
        const isLogin = getLoginInfo();
        // refresh customer temp cart
        // to sync if account logged in other device (prevent error)
        if (isLogin === 1 && tempCartId) {
            const resTempCartActiveQuote = await client.query({
                query: getTempCartActiveQuote,
                variables: { cartId: tempCartId },
                fetchPolicy: 'no-cache',
                context: {
                    request: 'internal',
                },
            });
            cartId = resTempCartActiveQuote.data.tempCart.active_quote_id;
            setCartId(resTempCartActiveQuote.data.tempCart.active_quote_id);

            tempCartId = resTempCartActiveQuote.data.tempCart.id;
            setTempCartId(resTempCartActiveQuote.data.tempCart.id);
        }
        // end refresh customer temp cart
        if (tempCartId) {
            clearMagentoCart({
                variables: {
                    cartId: tempCartId,
                },
            });
        }
    }, []);

    return (
        <Layout {...props} pageConfig={config} {...other}>
            <Content storeConfig={storeConfig} {...other} />
        </Layout>
    );
};

export default HomeCore;
