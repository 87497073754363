/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import getPath from '@helper_getpath';
import { setResolver, getResolver } from '@helper_localstorage';
import { features } from '@config';
import Link from 'next/link';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIos';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import classNames from 'classnames';
import { useRouter } from 'next/router';

// function to detect change of window screen size
const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

const Menu = (props) => {
    const windowSizeWidth = useWindowSize()[0];
    const { data, storeConfig } = props;
    const router = useRouter();
    const cmsPages = storeConfig && storeConfig.cms_page ? storeConfig.cms_page.split(',') : [];
    const menu = features.vesMenu.enabled ? data.vesMenu.items : data.categoryList[0].children;

    const linkTargetValue = (link_target) => {
        if (link_target === '_blank') {
            return {
                target: '_blank',
            };
        }
        return {};
    };

    // valid url should be including https:// or http://
    const validateUrl = (urlString) => {
        try {
            return Boolean(new URL(urlString));
        } catch (e) {
            return false;
        }
    };

    const generateLink = (cat) => {
        const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
        if (features.vesMenu.enabled) {
            // handle link outside origin
            // e.g https://google.com
            if (cat.link_type === 'custom_link') {
                const is_valid_url = validateUrl(cat.link);
                const is_valid_base_url = validateUrl(storeConfig.base_url);
                const checked_url = is_valid_url ? cat.link : '#';
                if (checked_url === '#') {
                    return [router.asPath, router.asPath];
                }
                if (!is_valid_base_url) {
                    return [checked_url, checked_url];
                }
                const complete_url = new URL(checked_url);
                const magento_url = new URL(storeConfig.base_url);
                const is_9to9_hostname = complete_url.hostname.includes(magento_url.hostname);
                if (!is_9to9_hostname) {
                    return [checked_url, checked_url];
                }
                return ['/[...slug]', link];
            }
            if (cat.link_type === 'category_link') {
                return ['/[...slug]', link];
            }
            const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
            if (cms) {
                return ['/[...slug]', link];
            }
            return [link, link];
        }
        return ['/[...slug]', link];
    };
    const handleClick = async (cat) => {
        const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
        const urlResolver = getResolver();
        if (features.vesMenu.enabled) {
            if (cat.link_type === 'category_link') {
                urlResolver[link] = {
                    type: 'CATEGORY',
                    id: cat.category_id,
                };
                await setResolver(urlResolver);
            } else {
                const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
                if (cms) {
                    urlResolver[link] = {
                        type: 'CMS_PAGE',
                    };
                    await setResolver(urlResolver);
                }
            }
        } else {
            urlResolver[link] = {
                type: 'CATEGORY',
                id: cat.id,
            };
            await setResolver(urlResolver);
        }
    };

    // check nav overflow to display scroll button and set nav position (center or flex-start)
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const navWrapper = document.getElementById('nav-wrapper');
            const navComponent = document.getElementById('nav');
            const arrow = document.getElementsByClassName('nav-arrow');
            const navItem = document.getElementsByClassName('nav-item');

            const navWrapperInfo = navWrapper.getBoundingClientRect();

            let navItemTotalWidth = 0;
            navItem.forEach((item) => {
                const itemInfo = item.getBoundingClientRect();
                navItemTotalWidth += itemInfo.width;
            });

            // if navItemTotalWidth value more than navWrapperInfo.width
            // it means overflow detected
            if (navItemTotalWidth > navWrapperInfo.width) {
                navWrapper.classList.remove('nav-full-width');
                navComponent.classList.add('nav-overflow');
                arrow.forEach((arr) => {
                    arr.classList.remove('nav-arrow-no-visible');
                });
            } else {
                navWrapper.classList.add('nav-full-width');
                navComponent.classList.remove('nav-overflow');
                arrow.forEach((arr) => {
                    arr.classList.add('nav-arrow-no-visible');
                });
            }
        }
    }, [windowSizeWidth]);

    // scroll nav by click
    const scrollNav = (scrollValue) => {
        const navComponent = document.getElementById('nav');
        return navComponent.scrollBy(scrollValue, 0);
    };

    return (
        <div className="menu-wrapper" role="navigation">
            <div className="nav-arrow nav-arrow-no-visible" onClick={() => scrollNav(-150)}>
                <LeftArrowIcon fontSize="inherit" />
            </div>
            <div id="nav-wrapper" className="nav-full-width">
                <ul className="nav" id="nav" role="menubar">
                    {menu.map((val, idx) => {
                        if ((val.include_in_menu || features.vesMenu.enabled) && val.name) {
                            const hyperlinkProps = { ...linkTargetValue(val.link_target) };
                            return (
                                <li className="nav-item" key={idx} role="menuitem">
                                    {val.link ? (
                                        <Link
                                            href={generateLink(val)[0]}
                                            as={generateLink(val)[1]}
                                            prefetch={false}
                                        >
                                            <a
                                                {...hyperlinkProps}
                                                onClick={() => {
                                                    if (typeof hyperlinkProps.target === 'undefined') handleClick(val);
                                                }}
                                                dangerouslySetInnerHTML={{ __html: val.name }}
                                                className="nav-item-link avenir-black"
                                            />
                                        </Link>
                                    ) : (
                                        <a
                                            href="#"
                                            dangerouslySetInnerHTML={{ __html: val.name }}
                                            className="nav-item-link avenir-black"
                                        />
                                    ) }
                                    {val.children.length > 0 ? (
                                        <div className="mega-menu row" aria-hidden="true" role="menu">
                                            {
                                                val.banner_url ? (
                                                    <div className="nav-banner col-lg-3 col-md-3 col-sm-3">
                                                        <img src={val.banner_url} alt="nav banner" />
                                                    </div>
                                                ) : null
                                            }
                                            <div
                                                className={
                                                    classNames(
                                                        'nav-column row',
                                                        val.banner_url ? 'col-lg-9 col-md-9 col-sm-9'
                                                            : 'col-lg-12 col-md-12 col-sm-12',
                                                    )
                                                }
                                            >
                                                {val.children.map((child, idxChild) => {
                                                    const childHyperlinkProps = { ...linkTargetValue(child.link_target) };
                                                    return (
                                                        <div key={idxChild} className="list-item col-lg-3 col-md-4 col-sm-6">
                                                            <Link
                                                                href={generateLink(child)[0]}
                                                                as={generateLink(child)[1]}
                                                                prefetch={false}
                                                            >
                                                                <a
                                                                    {...childHyperlinkProps}
                                                                    onClick={() => {
                                                                        if (typeof childHyperlinkProps.target === 'undefined') handleClick(child);
                                                                    }}
                                                                    className="avenir-black"
                                                                >
                                                                    {
                                                                        child.name ? (
                                                                            <>{child.name}</>
                                                                        ) : <>&nbsp;</>
                                                                    }
                                                                </a>
                                                            </Link>
                                                            {
                                                                child.children.length > 0 ? (
                                                                    <ul className="list-item__menu">
                                                                        {child.children.map((child2, idxChild2) => {
                                                                            const child2HyperlinkProps = { ...linkTargetValue(child2.link_target) };
                                                                            return (
                                                                                <li key={idxChild2}>
                                                                                    <Link
                                                                                        href={generateLink(child2)[0]}
                                                                                        as={generateLink(child2)[1]}
                                                                                        prefetch={false}
                                                                                    >
                                                                                        <a
                                                                                            {...child2HyperlinkProps}
                                                                                            onClick={() => {
                                                                                                // eslint-disable-next-line max-len
                                                                                                if (typeof child2HyperlinkProps.target === 'undefined') handleClick(child2);
                                                                                            }}
                                                                                        >
                                                                                            {child2.name}
                                                                                        </a>
                                                                                    </Link>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                ) : null
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : null}
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            </div>
            <div className="nav-arrow nav-arrow-no-visible" onClick={() => scrollNav(150)}>
                <RightArrowIcon fontSize="inherit" />
            </div>
            <style jsx global>
                {`
                    .menu-wrapper {
                        display: flex;
                        align-items: center;
                    }
                    .nav.nav-overflow {
                        justify-content: flex-start !important;
                        overflow-x: auto !important;
                    }
                    .nav-arrow {
                        width: 2.5%;
                        font-size: 14px;
                        color: #000000;
                        cursor: pointer;
                        text-align: center;
                    }
                    .nav-arrow.nav-arrow-no-visible {
                        display: none !important;
                    }
                    #nav-wrapper {
                        width: 95%;
                    }
                    #nav-wrapper.nav-full-width {
                        width: 100% !important;
                    }
                    .nav::-webkit-scrollbar {
                        display: none;
                    }
                    .nav {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        overflow-x: hidden;
                        scroll-behavior: smooth;
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                    }
                    .nav-banner {
                        padding: 0 24px;
                    }
                    .nav-banner img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                    .nav-column {
                        text-transform: capitalize;
                    }
                    .nav-item-link {
                        display: flex;
                        align-items: center;
                    }
                    .nav li, ul.list-item__menu {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                    }
                    li.nav-item {
                        border-bottom: 3px solid transparent;
                        font-size: 14px;
                        line-height: 19px;
                        text-transform: uppercase;
                        padding: 14px 28px;
                        flex-shrink: 0;
                        transition: all 0.2s ease 0.1s;
                    }
                    li.nav-item:hover {
                        border-bottom: 3px solid #000000;
                    }
                    .mega-menu {
                        background: #fff;
                        border-radius: 0 0 3px 3px;
                        opacity: 0;
                        position: absolute;
                        transition: all 0.2s ease 0.1s;
                        visibility: hidden;
                        width: 100%;
                        max-width: 1440px;
                        left: 50%;
                        transform: translateX(-50%);
                        margin: 16px 0 0 0;
                        height: 300px;
                        padding: 24px 32px 48px 32px;
                        z-index: 100;
                        overflow-y: auto;
                        box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.15);
                        -webkit-box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.15);
                    }
                    li:hover > .mega-menu {
                        opacity: 1;
                        visibility: visible;
                    }
                    .list-item {
                        padding: 0 24px;
                        margin-bottom: 12px;
                    }
                    ul.list-item__menu {
                        margin-top: 12px;
                    }
                    ul.list-item__menu li {
                        font-size: 14px;
                        line-height: 22px;
                    }
                `}
            </style>
        </div>
    );
};

export default Menu;
