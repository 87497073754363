/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import { useApolloClient } from '@apollo/client';
import { bulkSkuMagentoQuery } from '@core_modules/catalog/services/graphql/productSchema';

export const useBulkQuery = (productsSku) => {
    const client = useApolloClient();
    const [dataBulk, setDataBulk] = React.useState([]);

    const callQuery = async () => {
        try {
            const { data } = await client.query({
                query: bulkSkuMagentoQuery,
                variables: {
                    bulkSku: productsSku,
                },
            });

            const resultArrayData = data.customProductSearch;
            setDataBulk(resultArrayData);
        } catch (e) {
            setDataBulk([]);
        }
    };

    React.useEffect(() => {
        if (productsSku && productsSku.length > 0) {
            callQuery();
        }
    }, [productsSku]);

    return dataBulk;
};
