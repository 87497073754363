import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as schemaCategory from './categorySchema';
import * as productSchema from './productSchema';
import * as ActionSchema from './actionSchema';
import * as Schema from './schema';

export const getAggregationAmoeba = (config, otherConfig = {}) => useQuery(productSchema.getAggregationAmoebaSchema(config), otherConfig);
export const getProduct = (config, otherConfig = {}) => useQuery(productSchema.getProduct(config), otherConfig);
export const getProductAggregations = (options) => useQuery(productSchema.getProductAggregations(), {
    ...options,
});
export const getCategory = (variables) => useQuery(schemaCategory.getCategory(variables));
export const getCategoryProducts = (variables) => useQuery(schemaCategory.getCategoryProducts(variables));
export const getAmoebaCategoryProducts = (variables) => useQuery(schemaCategory.getAmoebaCategoryProducts, {
    variables,
    context: {
        request: 'amoebaMiddleware',
    },
});
export const getFilter = (catId) => useQuery(schemaCategory.getFilter(catId), { ssr: true });
export const addWishlist = () => useMutation(productSchema.addWishlist, {
    context: {
        request: 'internal',
    },
});

export const getConfigurableProduct = (sku) => useQuery(Schema.getConfigurableProduct(sku));
export const getBundleProduct = (sku) => useQuery(Schema.getBundleProduct(sku));
export const getDownloadroduct = (sku) => useQuery(Schema.getDownloadProduct(sku));
export const getGroupedProduct = (sku) => useQuery(Schema.getGroupedProduct, {
    variables: { sku },
    skip: !sku,
});

// actions add to cart

export const addSimpleProductsToCart = () => useMutation(ActionSchema.addSimpleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addVirtualProductToCart = () => useMutation(ActionSchema.addVirtualProductToCart, {
    context: {
        request: 'internal',
    },
});

export const addDownloadProductToCart = () => useMutation(ActionSchema.addDownloadableProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addConfigProductsToCart = () => useMutation(ActionSchema.addConfigProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addBundleProductsToCart = () => useMutation(ActionSchema.addBundleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const getGuestCartId = () => useMutation(ActionSchema.createCartIdGuest);
export const getCustomerCartId = () => useLazyQuery(ActionSchema.getCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
});

export const addProductsToCart = () => useMutation(ActionSchema.addProductToCart, {
    context: {
        request: 'internal',
    },
});

export const amoebaGetProductByCategory = (config, otherConfig = {}) => useQuery(productSchema.amoebaProductsByCategory(config), otherConfig);
export const getMagentoPrice = (sku) => useQuery(productSchema.getMagentoPriceSchema(sku));

export const getCategoryEvent = (variables) => useQuery(schemaCategory.getCategoryEvent(variables), {
    fetchPolicy: 'no-cache',
});

export default { getCategory, getCategoryProducts, getCategoryEvent };
