import React from 'react';
import { withTranslation } from '@i18n';
import dynamic from 'next/dynamic';

const BannerSliderSkeleton = dynamic(() => import('@core_modules/home/pages/default/components/Skeleton/BannerSkeleton'));
const BannerView = dynamic(() => import('@core_modules/home/pages/default/components/Banner/view'));
const BannerSlider = dynamic(() => import('@core_modules/home/pages/default/components/Banner'));
const ErrorInfo = dynamic(() => import('@core_modules/home/pages/default/components/ErrorInfo'), { ssr: false });

const WidgetSlider = (props) => {
    const { ...other } = props;

    return <BannerSlider BannerSliderSkeleton={BannerSliderSkeleton} BannerView={BannerView} ErrorInfo={ErrorInfo} {...other} />;
};

export default withTranslation()(WidgetSlider);
