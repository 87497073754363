import Cookies from 'js-cookie';
import { nameCartId, nameTempCartId, expiredDefault } from '@config';

// magento cart
export const getCartId = () => {
    const cartId = Cookies.get(nameCartId);
    return cartId;
};

export const setCartId = (token, expired) => {
    Cookies.set(nameCartId, token, { expires: expired || expiredDefault });
    return true;
};

export const removeCartId = () => {
    Cookies.remove(nameCartId);
    return true;
};
// end magento cart

// temp cart
export const getTempCartId = () => {
    const cartId = Cookies.get(nameTempCartId);
    return cartId;
};

export const setTempCartId = (token, expired) => {
    Cookies.set(nameTempCartId, token, { expires: expired || expiredDefault });
    return true;
};

export const removeTempCartId = () => {
    Cookies.remove(nameTempCartId);
    return true;
};
// end temp cart

export default {

};
