import Banner from '@common_slick/Banner';
import useStyles from '../style';

const MultiBannerView = (props) => {
    const { images } = props;
    const styles = useStyles();
    const bannerImageWithoutMobile = images.map((image) => ({
        imageUrl: image.imageUrl,
        link: image.link,
        custom_html_content: image.custom_html_content,
    }));

    return (
        <div className={styles.multiSliderBanner}>
            {images
                && images.length
                && (
                    <Banner
                        desktopSlider={3}
                        data={bannerImageWithoutMobile}
                        showHtmlContent
                        imageAutoHeight
                        showDot={false}
                        width={413}
                    />
                )}
        </div>
    );
};

export default MultiBannerView;
