import Cookies from 'js-cookie';

export const getSliderFetchTimestamp = () => Cookies.get('sliderFetchTimestamp') || '';

export const roundToBottomMinutes = () => {
    const currentTime = new Date();
    const minutes = currentTime.getMinutes();

    const roundMinute = Math.floor(minutes / 15) * 15;

    currentTime.setMinutes(roundMinute);
    currentTime.setSeconds(0);
    currentTime.setMilliseconds(0);

    return currentTime.getTime();
};

const refetchBannerSlider = (refetch) => {
    refetch();
};

export default refetchBannerSlider;
