/* eslint-disable no-nested-ternary */

export const transformType = (typeId) => {
    if (typeId === 'simple') {
        return 'SimpleProduct';
    } if (typeId === 'configurable') {
        return 'ConfigurableProduct';
    } if (typeId === 'virtual') {
        return 'VirtualProduct';
    } if (typeId === 'bundle') {
        return 'BundleProduct';
    } if (typeId === 'downloadable') {
        return 'DownloadableProduct';
    }
    return undefined;
};

export const finalPriceConversion = (price) => (price.length > 0
    ? price[0].is_discount ? price[0].final_price : price[0].price
    : null);

export const transformAmoebaData = (dataAmoeba, isAmoeba) => {
    if (dataAmoeba) {
        if (isAmoeba) {
            return {
                __typename: dataAmoeba && transformType(dataAmoeba.type_id),
                id: dataAmoeba && dataAmoeba.id,
                sku: dataAmoeba.sku,
                name: dataAmoeba.name,
                url_key: dataAmoeba.url_key,
                categories: dataAmoeba.category,
                price_tiers: [],
                price_range: {
                    __typename: 'PriceRange',
                    maximum_price: {
                        __typename: 'ProductPrice',
                        discount: {
                            __typename: 'ProductDiscount',
                            amount_off: dataAmoeba.price.length > 0
                                && dataAmoeba.price[0].original_price - dataAmoeba.price[0].final_price,
                            percent_off: dataAmoeba.price_discount ? dataAmoeba.price_discount : 0,
                        },
                        final_price: {
                            __typename: 'Money',
                            currency: 'IDR',
                            // value: dataAmoeba.price.length > 0 && dataAmoeba.price[0].final_price
                            value: finalPriceConversion(dataAmoeba.price),
                        },
                        regular_price: {
                            __typename: 'Money',
                            currency: 'IDR',
                            value: dataAmoeba.price.length > 0 && dataAmoeba.price[0].original_price,
                        },
                    },
                    minimum_price: {
                        __typename: 'ProductPrice',
                        discount: {
                            __typename: 'ProductDiscount',
                            amount_off: dataAmoeba.price.length > 0
                                && dataAmoeba.price[0].original_price - dataAmoeba.price[0].final_price,
                            percent_off: dataAmoeba.price_discount ? dataAmoeba.price_discount : 0,
                        },
                        final_price: {
                            __typename: 'Money',
                            currency: 'IDR',
                            // value: dataAmoeba.price.length > 0 && dataAmoeba.price[0].final_price
                            value: finalPriceConversion(dataAmoeba.price),
                        },
                        regular_price: {
                            __typename: 'Money',
                            currency: 'IDR',
                            value: dataAmoeba.price.length > 0 && dataAmoeba.price[0].original_price,
                        },
                    },
                },
                special_from_date: null,
                special_to_date: null,
                new_from_date: null,
                new_to_date: null,
                image: {
                    __typename: 'ProductImage',
                    url: dataAmoeba.image[0],
                    label: dataAmoeba.name,
                },
                small_image: {
                    __typename: 'ProductImage',
                    url: dataAmoeba.image[0],
                    label: dataAmoeba.name,
                },
                additional_data: {
                    __typename: 'DataSummary',
                    sold_qty: 2,
                    label: "['test']",
                },
            };
        }
        return dataAmoeba;
    }
    return null;
};
