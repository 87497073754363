/* eslint-disable no-unused-vars */
import React from 'react';

const WidgetView = (props) => {
    const {
        WidgetComponent,
    } = props;
    return (
        <>
            <WidgetComponent />
        </>
    );
};

export default WidgetView;
