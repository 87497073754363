/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import Typograpy from '@common_typography';
import useStyles from './style';

const SelectOption = (props) => {
    const {
        value, selected, onChange, className = '', disabled = false, label = '', content = '',
        labelClassName = '', salableQtyChildProducts, attributeCode,
    } = props;

    let emptyStock = false;
    const currentSalableQty = salableQtyChildProducts?.filter((filtered) => filtered[attributeCode] === value);

    if (!currentSalableQty?.length) {
        // salable qty on current size value is not found
        emptyStock = true;
    } else {
        // all of salable qty on current size value are 0
        const checkZeroStock = currentSalableQty.filter((filtered) => filtered.qty === 0);
        if (checkZeroStock.length === currentSalableQty.length) emptyStock = true;
    }

    const styles = useStyles();
    const containerStyle = selected && (!disabled && !emptyStock)
        ? classNames(
            styles.container,
            styles.bordered,
            className,
        )
        : classNames(styles.container, (disabled || emptyStock) ? styles.hoverDisabled : '', styles.notBordered, className);
    const labelStyle = selected
        ? classNames(styles.label, styles.labelActive, labelClassName, 'avenir-medium')
        : classNames(styles.label, (disabled || emptyStock) ? styles.labelDisabled : '', labelClassName, 'avenir-medium');
    const customStyle = {
        border: `3px solid ${selected ? '#FF6A00' : '#F2F2F2'}`,
    };

    if (content === '' && label === '') return null;

    const childContent = <Typograpy className={labelStyle}>{content || label}</Typograpy>;

    const handleChange = () => {
        // eslint-disable-next-line no-unused-expressions
        (!disabled && !emptyStock) && onChange(value);
    };
    return (
        <div style={customStyle} className={containerStyle} onClick={handleChange}>
            {childContent}
        </div>
    );
};

export default SelectOption;
