import { gql } from '@apollo/client';
import { modules } from '@config';

const customizable_options = `
    customizable_options {
        id
        label
        is_required
        sort_order
        values {
            label
            value
            price {
                type
                units
                value
            }
        }
    }
`;

export const getCmsPage = gql`
    query($identifier: String!) {
        cmsPage(identifier: $identifier) {
            identifier
            content
            meta_description
            meta_keywords
            meta_title
            title
            url_key
        }
    }
`;

export const getProductBySkuQuery = gql`
    query getProductBySku($bulkSku: [String]) {
        customProductSearch(bulk_sku: $bulkSku) {
            name
            sku
            url_key
            ... on ConfigurableProduct {
                variants {
                    product {
                        name
                        sku
                    }
                }
            }
        }
    }
`;

// magento cart
export const getCartIdUser = gql`
    {
        customerCart {
            id
        }
    }
`;

export const createCartIdGuest = gql`
    mutation {
        createEmptyCart
    }
`;
// end magento cart

// temp cart
export const getTempCartIdUser = gql`
    {
        customerTempCart {
            id
        }
    }
`;

export const createTempCartIdGuest = gql`
    mutation {
        createEmptyTempCart
    }
`;
// end temp cart

export const addSimpleProductsToCart = gql`
    mutation addSimpleProductsToCart(
        $cartId: String!,
        $qty: Float!,
        $sku: String!,
        ${modules.product.customizableOptions.enabled
        && `
        $customizable_options: [CustomizableOptionInput],
        $entered_options: [EnteredOptionInput] 
        `}
    ) {
        addSimpleProductsToCart(input:{
            cart_id: $cartId,
            cart_items: {
                ${modules.product.customizableOptions.enabled
                && ' customizable_options: $customizable_options'}
                data: {
                    quantity: $qty,
                    sku: $sku,
                    ${modules.product.customizableOptions.enabled
                        && ' entered_options: $entered_options'}
                }
            }
        }) {
            cart {
                id
                total_quantity
            }
        }
    }
`;

export const addConfigProductsToCart = gql`
    mutation (
        $cartId: String!,
        $qty: Float!,
        $sku: String!,
        $parentSku: String!,  
        ${modules.product.customizableOptions.enabled
            && `
            $customizable_options: [CustomizableOptionInput],
            $entered_options: [EnteredOptionInput] 
        `}
    ) {
        addConfigurableProductsToCart(
            input: {
                cart_id: $cartId,
                cart_items: {
                    ${modules.product.customizableOptions.enabled
                    && ' customizable_options: $customizable_options'}
                    data: {
                        quantity : $qty,
                        sku: $sku,
                        ${modules.product.customizableOptions.enabled
                            && ' entered_options: $entered_options'}
                    }
                    parent_sku: $parentSku
                }
            }
        ) {
            cart {
                id
                total_quantity
            }
        }
    }
`;

export const getCartItem = gql`
    query getCartItem($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            errorItems
            total_quantity
            items {
                id
                quantity
                product {
                    id
                    name
                    sku
                }
                stock_information
                ... on SimpleCartItem {
                    SimpleMiniCustomizable: ${customizable_options}
                }
                ... on ConfigurableCartItem {
                    ConfigurableMiniCustomizable: ${customizable_options}
                    configurable_options {
                        option_label
                        value_label
                    }
                }
            }
        }
    }
`;

// magento cart item
export const getCartSyncItem = gql`
    query getCartSyncItem($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            errorItems
            total_quantity
            items {
                id
                cart_item_sku
                cart_item_name
                quantity
                prices {
                    price_including_tax {
                        currency
                        value
                    }
                }
                product {
                    id
                    name
                    sku
                    stock_status
                    description {
                        html
                    }
                    media_gallery {
                        url
                        label
                    }
                    url_key
                }
                stock_information
                ... on SimpleCartItem {
                    SimpleMiniCustomizable: ${customizable_options}
                }
                ... on ConfigurableCartItem {
                    ConfigurableMiniCustomizable: ${customizable_options}
                    configurable_options {
                        option_label
                        value_label
                    }
                }
            }
        }
    }
`;
// end magento cart item

// temp cart item
export const getTempCartItem = gql`
    query getTempCartItem($cartId: String!) {
        tempCart(cart_id: $cartId) {
          active_quote_id
          email
          id
          total_quantity
          items {
            additional_info {
              label
              value
            }
            id
            active_item_id
            is_selected_to_checkout
            prices {
              price {
                value
                currency
              }
              row_total {
                value
                currency
              }
            }
            product {
              product_id
              vendor_name
              vendor_code
              image
              name
              sku
              url_key
            }
            quantity
            stock_status
            stock_information
          }
        }
    }
`;
// end temp cart item

// update temp cart item
export const updateTempCartItem = gql`
    mutation updateTempCartItems($cartId: String!, $cartItems: [UpdateTempCartItemsInput!]!) {
      updateTempCartItems(
        cartId: $cartId,
        cartItems: $cartItems,
      ) {
        tempCart {
          id
          total_quantity
        }
      }
    }
`;
// update temp cart item

// update magento cart item
export const updateCartitem = gql`
    mutation updateCartItems($cartId: String!, $cart_item_id: Int!, $quantity: Float!) {
      updateCartItems(
        input: { 
          cart_id: $cartId,
          cart_items: {cart_item_id: $cart_item_id, quantity: $quantity }
        }
      ) {
        cart {
            id
            total_quantity
        }
      }
    }
`;
// end update magento cart item

// add product to temp cart
export const addProductsToTempCart = gql`
  mutation addProductsToTempCart(
      $cartId: String!,
      $cartItems: [TempCartItemsInput!]!,
  ) {
    addProductsToTempCart(
      cartId: $cartId,
      cartItems: $cartItems,
    ) {
      tempCart {
        id
        active_quote_id
        total_quantity
      }
    }
  }
`;
// end add product to temp cart

export default { getCmsPage };
