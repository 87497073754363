import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as Schema from './schema';

export const getCmsPage = (variables) => useQuery(Schema.getCmsPage, { variables });

// magento cart
export const createCartIdGuest = () => useMutation(Schema.createCartIdGuest, {
    context: {
        request: 'internal',
    },
});

export const getCustomerCartId = () => useLazyQuery(Schema.getCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
});
// end magento cart

// temp cart
export const getGuestTempCartId = () => useMutation(Schema.createTempCartIdGuest);

export const getCustomerTempCartId = () => useLazyQuery(Schema.getTempCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
});
// end temp cart

export const addSimpleProductsToCart = () => useMutation(Schema.addSimpleProductsToCart, {
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
});

export const addConfigProductsToCart = () => useMutation(Schema.addConfigProductsToCart, {
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
});

// update temp cart item
export const updateTempCartItem = () => useMutation(Schema.updateTempCartItem, {
    context: {
        request: 'internal',
    },
});
// end update temp cart item

// update magento cart item
export const updateCartitem = () => useMutation(Schema.updateCartitem, {
    context: {
        request: 'internal',
    },
});
// end update magento cart item

// add product to temp cart
export const addProductsToTempCart = () => useMutation(Schema.addProductsToTempCart, {
    context: {
        request: 'internal',
    },
});
// end add product to temp cart

export default { getCmsPage };
