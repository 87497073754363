import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
    container: {
        height: 'auto',
    },
    btnCategory: {
        background: '#FF6A00 0% 0% no-repeat padding-box !important',
        borderRadius: 1,
        width: '172px',
        '&:hover': {
            background: '#FF6A00 0% 0% no-repeat padding-box',
        },
        padding: 1,
        boxShadow: 'none',
        [theme.breakpoints.down(1030)]: {
            width: '120px',
        },
        [theme.breakpoints.down(890)]: {
            width: '40px',
            height: '30px',
        },
    },
    textBtn: {
        color: '#FFFF',
        [theme.breakpoints.down(1030)]: {
            fontSize: '11px',
        },
        [theme.breakpoints.down(890)]: {
            display: 'none',
        },
    },
    iconDehaze: {
        color: '#FFFF',
        [theme.breakpoints.down(1030)]: {
            fontSize: '16px',
        },
    },
}));
