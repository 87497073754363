/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
import Alert from '@material-ui/lab/Alert';
import parse from 'html-react-parser';
import dynamic from 'next/dynamic';

const Loading = dynamic(() => import('@common_loaders/Backdrop'), { ssr: false });
const Copyright = dynamic(() => import('@core_modules/theme/components/footer/desktop/components/copyright'), { ssr: false });
const Newsletter = dynamic(() => import('@core_modules/customer/plugins/Newsletter'), { ssr: false });
const WidgetThumborImage = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-thumbor-image'), { ssr: false });

const FooterView = (props) => {
    const {
        data, t, loading, error, storeConfig, footerMobile = true, spanBottom,
    } = props;
    if (error) {
        return (
            <Alert className="m-15" severity="error">
                {t('common:error:fetchError')}
            </Alert>
        );
    }

    const options = {
        replace: (domNode) => {
            if (!domNode.attribs) {
                return;
            }
            if (domNode.attribs && domNode.attribs.class === 'pwa-footer-newsletter-right') {
                return <Newsletter />;
            } if (domNode.name === 'img') {
                return (
                    <WidgetThumborImage {...domNode.attribs} />
                );
            } 
        },
    };

    if (loading) return <Loading open={loading} />;
    return (
        <div className="cms-container wrapper-footer">
            <div className={footerMobile ? '' : 'hidden-mobile'}>
                {parse(data.cmsBlocks.items[0].content, options)}
                <Copyright storeConfig={storeConfig} spanBottom={spanBottom} />
            </div>
        </div>
    );
};

export default FooterView;
