import dynamic from 'next/dynamic';

const Content = dynamic(() => import('@core_modules/home/pages/default/components'));
const Core = dynamic(() => import('@core_modules/home/pages/default/core'));
const ErrorInfo = dynamic(() => import('@core_modules/home/pages/default/components/ErrorInfo'), { ssr: false });

const Page = (props) => (
    <Core
        Content={Content}
        ErrorInfo={ErrorInfo}
        {...props}
    />
);

export default Page;
