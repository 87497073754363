import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    noMargin: {
        margin: 0,
    },
    textPrice: {
        color: '#FF6A00',
        fontSize: 18,
        fontWeight: 'bold !important',
        '& span.product-list-text-price': {
            fontSize: 13,
        },
        '& span.default-text-price': {
            fontSize: 24,
            color: '#313133',
            lineHeight: '1.26',
        },
    },
    textDiscount: {
        color: '#FF6A00',
        fontSize: 9,
    },
    textPriceDiscount: {
        color: '#FF6A00',
        textAlign: 'left',
        fontWeight: 'bold !important',
        lineHeight: '1.26',
    },
    container: {
        width: '100%',
        '& .discount-container': {
            padding: '10px 0',
            fontWeight: 'bold !important',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'flex-start',
            },
            '& .line-through-price': {
                marginRight: '4px',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        '& .configurable-discount-container': {
            padding: '10px 0',
            fontWeight: 'bold !important',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                alignItems: 'flex-start',
            },
            '& .configurable-old-price': {
                margin: '0 4px',
            },
        },
        '& .discount-container, & .configurable-discount-container': {
            '& .text-discount': {
                color: '#FF6A00',
                fontSize: 9,
            },
            '& .old-price': {
                textDecoration: 'line-through',
                fontSize: 9,
                color: '#9B9B9B',
            },
            '& .text-price-discount': {
                fontSize: 13,
                color: '#FF6A00',
            },
        },
    },
    noPaddingBottom: {
        paddingBottom: '0 !important',
    },
    noPaddingTop: {
        paddingTop: '0 !important',
    },
    oldPrice: {
        fontSize: 13,
        color: '#9B9B9B',
    },
    discountContainer: {
        display: 'flex',
        width: '100%',
        marginTop: '12px',
    },
    priceDiscount: {
        background: '#FF6A00',
        padding: '0 2px',
        width: 'fit-content',
    },
    discountWishlist: {
        display: 'block',
    },
    productListPrice: {
        padding: '5px 0',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

export default useStyles;
