/* eslint-disable max-len */
import { withApollo } from '@lib_apollo';
import { useState } from 'react';
import dynamic from 'next/dynamic';

const BrowseModal = dynamic(() => import('@common_searchmodal'), { ssr: false });
const Navigator = dynamic(() => import('@core_modules/theme/components/bottom-navigation/mobile/Navigator'));

const Navigation = ({ active, storeConfig }) => {
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (val) => {
        setOpenModal(val);
    };
    if (active) {
        return (
            <>
                <BrowseModal open={openModal} setOpenModal={handleOpenModal} storeConfig={storeConfig} />
                <Navigator active={active} handleOpenModal={handleOpenModal} />
            </>
        );
    }
    return null;
};

export default withApollo({ ssr: false })(Navigation);
