/* eslint-disable no-plusplus */
import {
    useState, useRef, useEffect,
} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Router from 'next/router';
import {
    getProductResult,
    getCategoryResult,
    getAmoebaResult,
} from '@core_modules/theme/services/graphql/schema';
import { useTranslation } from '@i18n';
import { finalPriceConversion } from '@root/src/helpers/transformAmoebaData';
import { getLoginInfo } from '@helper_auth';
import useStyles from '@core_modules/theme/components/header/desktop/components/autocomplete/style';
import { useApolloClient } from '@apollo/client';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState('');
    const timerRef = useRef();

    useEffect(() => {
        timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [value, delay]);

    return [debouncedValue, setDebouncedValue];
}

const generateItemData = (product, category, isAmoeba, keyword) => {
    const result = [];
    if (isAmoeba) {
        for (let index = 0; index < product.length; index++) {
            const element = product[index];
            if (element.stock_status) {
                const prod = {
                    id: element.id,
                    name: element.name,
                    url_key: element.url_key,
                    position: index,
                    small_image: {
                        label: element.name,
                        url: element.image[0],
                    },
                    price_range: {
                        minimum_price: {
                            final_price: {
                                __typename: 'Money',
                                currency: 'IDR',
                                value: finalPriceConversion(element.price),
                            },
                        },
                    },
                    type: 'product',
                    keyword,
                };
                result.push(prod);
            }
        }
        for (let index = 0; index < category.length; index++) {
            const element = category[index];
            const cat = {
                id: element.id,
                name: element.name,
                url_key: element.url_path,
                // breadcrumbs: element.breadcrumbs,
                breadcrumbs: element.url_path,
                position: index,
                type: 'category',
                keyword,
            };
            result.push(cat);
        }
    } else {
        for (let index = 0; index < product.length; index++) {
            const element = product[index];
            const prod = {
                id: element.id,
                name: element.name,
                url_key: element.url_key,
                position: index,
                small_image: element.small_image,
                price_range: element.price_range,
                type: 'product',
                keyword,
            };
            result.push(prod);
        }
        for (let index = 0; index < category.length; index++) {
            const element = category[index];
            const cat = {
                id: element.id,
                name: element.name,
                url_key: element.url_path,
                breadcrumbs: element.breadcrumbs,
                position: index,
                type: 'category',
                keyword,
            };
            result.push(cat);
        }
    }
    return result;
};

export default function ComboBox(props) {
    const {
        handleSearch, value, setValue, OptionsItem,
        // storeConfig,
    } = props;

    const client = useApolloClient();

    const { t } = useTranslation(['common']);
    const styles = useStyles();
    const [item, setItem] = useState([]);
    const [open, setOpen] = useState(false);
    const [debouncedValue, setDebouncedValue] = useDebounce(value, 500);
    const [loading, setLoading] = useState(false);
    // const isAmoeba = storeConfig && storeConfig.amoeba;
    const isAmoeba = false;
    let isLogin = 0;

    if (typeof window !== 'undefined') {
        isLogin = getLoginInfo();
    }

    const handleKeyPress = (e) => {
        handleSearch(e);
    };

    async function generateResult() {
        setLoading(true);
        let productSuggestion = [];
        let categorySuggestion = [];
        let itemData = [];
        if (isAmoeba) {
            const { data: amoebaResult } = await client.query({
                query: getAmoebaResult,
                variables: { search: debouncedValue, isLogged: isLogin === 1 },
                errorPolicy: 'ignore',
                fetchPolicy: 'network-only',
                context: {
                    request: 'amoebaMiddleware',
                },
            });

            productSuggestion = amoebaResult?.search?.[0]?.product || [];
            categorySuggestion = amoebaResult?.search?.[0]?.category || [];
        } else {
            const [{ data: productResult }, { data: categoryResult }] = await Promise.all([
                client.query({
                    query: getProductResult,
                    variables: { search: debouncedValue },
                    errorPolicy: 'ignore',
                }),
                client.query({
                    query: getCategoryResult,
                    variables: { match: debouncedValue },
                    errorPolicy: 'ignore',
                }),
            ]);

            productSuggestion = productResult?.products?.items || [];
            categorySuggestion = categoryResult?.categoryList || [];
        }
        itemData = generateItemData(productSuggestion, categorySuggestion, isAmoeba, debouncedValue);
        setItem(itemData);
        setLoading(false);
    }

    useEffect(() => {
        if (debouncedValue.length > 2) {
            generateResult();
        } else {
            setItem([]);
        }
    }, [debouncedValue]);

    const noOptionsText = (debouncedValue.length > 2) ? t('common:search:noOptions') : t('common:search:min3chars');

    return (
        <Autocomplete
            id="autocomplete-search"
            classes={{
                inputRoot: styles.searchInputRoot,
            }}
            options={item}
            getOptionLabel={(option) => option.keyword}
            getOptionSelected={(option, newValue) => option.name === newValue.name}
            forcePopupIcon
            loading={loading}
            openOnFocus={false}
            open={open && (debouncedValue.length > 0) && (value === debouncedValue)}
            renderOption={(option) => <OptionsItem isAmoeba={isAmoeba} {...option} />}
            renderInput={(params) => (
                <TextField
                    id="autocomplete-search-input"
                    placeholder={t('common:search:sayHello')}
                    onBlur={() => {
                        setLoading(false);
                        setDebouncedValue('');
                        setValue('');
                        setOpen(false);
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    {...params}
                />
            )}
            onInputChange={(e) => {
                setValue(e.target.value);
                setOpen(true);
            }}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e, newValue) => {
                setLoading(false);
                setDebouncedValue('');
                setValue('');
                setOpen(false);
                Router.push(
                    '/[...slug]',
                    `/${newValue.url_key}`,
                );
            }}
            onClose={() => {
                setLoading(false);
                setDebouncedValue('');
                setValue('');
                setOpen(false);
            }}
            noOptionsText={noOptionsText}
        />
    );
}
