import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    navigation: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: 99,
        boxShadow: '0px 0 3px #0000001A',
    },
    navAction: {
        position: 'relative',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
    },
    navActionSelected: {
        '& svg': {
            // stroke: '#FF6A00',
            // color: '#FF6A00',
            fill: '#FF6A00',
        },
        '& svg *': {
            // stroke: '#FF6A00',
            // color: '#FF6A00',
            fill: '#FF6A00',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '4px',
            height: '4px',
            borderRadius: '100%',
            background: '#FF6A00',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%) scale(0.8)',
        },
    },
}));

export default useStyles;
