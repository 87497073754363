/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import schema, { getCmsBlocks as getCmsBlocksSchema } from './schema';

export const getCategories = () => useQuery(schema.categories);
export const getCategoryByName = (name) => useLazyQuery(schema.getCategoryByName(name));
export const getProduct = (key) => useLazyQuery(schema.getProduct(key));
export const getAmoebaSearch = (key, otherConfig = {}) => useLazyQuery(schema.getSearchAmoeba(key), otherConfig);
export const getVesMenu = (options) => useQuery(schema.vesMenu, options);
export const getCurrency = () => useQuery(schema.getCurrencySchema);

export const getCustomer = () => useQuery(schema.getCustomer, {
    context: {
        request: 'internal',
        skip: typeof window === 'undefined',
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
});

export const removeToken = () => useMutation(schema.removeToken, {
    context: {
        request: 'internal',
    },
});

export const getCmsBlocks = (variables) => useQuery(getCmsBlocksSchema, { variables });

export const addHitAffiliate = (options) => useMutation(schema.addHitAffiliate, {
    ...options,
    context: {
        request: 'internal',
    },
});

export const getCountCart = () => useLazyQuery(schema.getCountCart,
    {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export const getCountTempCart = () => useLazyQuery(schema.getCountTempCart,
    {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    });

export default {
    getCmsBlocks,
    getCategories,
    getCustomer,
    removeToken,
    getVesMenu,
    getProduct,
    getCategoryByName,
    getAmoebaSearch,
    getCurrency,
    addHitAffiliate,
    getCountCart,
    getCountTempCart,
};
