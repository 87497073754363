/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Thumbor from '@common_slick/Banner/Thumbor';
import { getProductVariantsQuery } from '@core_modules/product/services/graphql';
import useStyles from './style';

function reduceVariants(plainVariants) {
    return plainVariants.reduce((acc, curr) => {
        // eslint-disable-next-line no-unused-expressions
        curr?.attributes?.forEach((attribute) => {
            if (attribute?.code === 'color') {
                const attributeIndex = acc.findIndex((accIndex) => accIndex?.color === attribute?.value_index);
                if (attributeIndex < 0) {
                    acc.push({
                        [attribute.code]: attribute.value_index,
                        image: curr.product.image,
                    });
                }
            }
        });
        return acc;
    }, []);
}

const ColorSwatch = ({
    url_key = '',
    selectedColorSwatch,
    setSelectedColorSwatch,
    pageType = 'pdp',
    salableQtyChildProducts = [],
    productVariants = [],
}) => {
    const containerRef = useRef(null);
    const [displayPrevBtn, setDisplayPrevBtn] = useState(false);
    const styles = useStyles();
    let reducedVariants = [];
    if (pageType === 'product-list') {
        reducedVariants = reduceVariants(productVariants);
    } else {
        const resProductVariants = getProductVariantsQuery(url_key);
        reducedVariants = resProductVariants?.data?.customProductSearch?.[0]?.variants?.length > 0
            ? reduceVariants(resProductVariants.data.customProductSearch[0].variants)
            : [];
    }

    if (!reducedVariants.length) return null;

    // scroll by click
    const scrollByClick = (scrollValue) => {
        if (containerRef?.current) {
            const currentComponent = containerRef.current;
            currentComponent.scrollBy(scrollValue, 0);
            if ((currentComponent.scrollLeft + scrollValue) > 0) setDisplayPrevBtn(true);
            else setDisplayPrevBtn(false);
        }
    };

    if (pageType === 'product-list') {
        return (
            <div className={styles.containerContainer}>
                {
                    typeof window !== 'undefined' && displayPrevBtn && reducedVariants.length > 4 && (
                        <div className={styles.prevBtn} onClick={() => scrollByClick(-30)}>
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.835938 11.4163L6.2526 5.99967L0.835938 0.583008V11.4163Z" fill="black" />
                            </svg>
                        </div>
                    )
                }
                <div
                    ref={containerRef}
                    className={classNames(styles.container, 'container-product-list', reducedVariants.length <= 4 && 'container-product-list-center')}
                >
                    {
                        reducedVariants.map((variant, idx) => (
                            <div
                                onClick={() => {
                                    if (selectedColorSwatch?.color === variant.color) setSelectedColorSwatch({});
                                    else setSelectedColorSwatch({ color: variant.color, image: variant.image });
                                }}
                                className={styles.variantItemProductList}
                                key={idx}
                            >
                                <Thumbor
                                    src={variant.image.url}
                                    alt="color swatch"
                                    width={32}
                                    height={32}
                                    quality={100}
                                />
                            </div>
                        ))
                    }
                </div>
                {
                    typeof window !== 'undefined' && reducedVariants.length > 4 && (
                        <div className={styles.nextBtn} onClick={() => scrollByClick(30)}>
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.835938 11.4163L6.2526 5.99967L0.835938 0.583008V11.4163Z" fill="black" />
                            </svg>
                        </div>
                    )
                }
            </div>
        );
    }

    return (
        <div className={classNames(styles.container)}>
            {
                reducedVariants.map((variant, idx) => {
                    let emptyStock = false;
                    const currentSalableQty = salableQtyChildProducts?.filter((filtered) => filtered.color === variant.color);

                    if (!currentSalableQty?.length) {
                        // salable qty on current size value is not found
                        emptyStock = true;
                    } else {
                        // all of salable qty on current size value are 0
                        const checkZeroStock = currentSalableQty.filter((filtered) => filtered.qty === 0);
                        if (checkZeroStock.length === currentSalableQty.length) emptyStock = true;
                    }
                    return (
                        <div
                            onClick={() => {
                                if (!emptyStock) setSelectedColorSwatch({ color: variant.color });
                            }}
                            className={styles.variantItem}
                            key={idx}
                        >
                            {
                                emptyStock && (
                                    <div className={styles.overlayEmptyStock} />
                                )
                            }
                            <Thumbor
                                src={variant.image.url}
                                alt="color swatch"
                                width={70}
                                height={70}
                                quality={100}
                            />
                        </div>
                    );
                })
            }
            <div className={styles.lastItemIndicator} />
        </div>
    );
};

export default ColorSwatch;
