/* eslint-disable max-len */
import { modules } from '@config';
import dynamic from 'next/dynamic';
import useStyles from '@core_modules/home/pages/default/components/style';

const BannerSlider = dynamic(() => import('@core_modules/home/pages/default/components/Banner'));
const CategoryList = dynamic(() => import('@core_modules/home/pages/default/components/CategoryList'));
const CmsPage = dynamic(() => import('@core_modules/cms/pages/default'));
const FeaturedProducts = dynamic(() => import('@core_modules/home/pages/default/components/FeaturedProducts'));

const Content = (props) => {
    const styles = useStyles();
    const {
        BannerSliderSkeleton, BannerView, FeaturedSkeleton, FeaturedView, CategoryListSkeleton, CategoryListView, ...other
    } = props;
    const { useCmsPage } = modules.home;

    if (useCmsPage.enable) return <CmsPage onlyCms slug={[useCmsPage.identifier]} withLayoutHeader={false} withLayoutFooter={false} withCmsTitle={false} {...other} />;

    return (
        <>
            <style jsx>
                {`
                   .btn-place-order-product-list {
                        display: none !important;
                    }
                `}
            </style>
            <div className={styles.container}>
                <BannerSlider {...other} />
                <FeaturedProducts {...other} />
                <CategoryList {...other} />
            </div>
            <style jsx global>
                {`
                    .btn-place-order-product-list {
                        display: none !important;
                    }
                `}
            </style>
        </>
    );
};

export default Content;
