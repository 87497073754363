import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    skeletonContainer: {
        padding: '0 18px 18px 18px',
        [theme.breakpoints.down(1024)]: {
            padding: '0 6px 16px 6px !important',
        },
    },
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up(767)]: {
            height: 'auto',
        },
        [theme.breakpoints.down(768)]: {
            maxWidth: '100vw',
        },
    },
    arrow: {
        fontSize: '14px',
        backgroundColor: '#96999C',
        position: 'absolute',
        borderRadius: 666,
        top: '25%',
        width: 30,
        height: 30,
        cursor: 'pointer',
        transition: '0.25s ease-in-out',
        color: WHITE,
        '&:hover': {
            backgroundColor: PRIMARY,
        },
    },
    arrowIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    leftArrow: {
        left: 18,
    },

    rightArrow: {
        right: 18,
    },
}));

export default useStyles;
