import Thumbor from '@common_image';

const WidgetThumborImage = (props) => {
    const {
        src,
        alt = 'image',
        width = 0,
        height = 0,
        class: className = '',
        loading = 'lazy',
    } = props;

    return (
        <Thumbor
            className={className}
            src={src}
            width={width}
            height={height}
            quality={80}
            alt={alt}
            lazy={loading === 'lazy'}
        />
    );
};

export default WidgetThumborImage;
