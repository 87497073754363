/* eslint-disable react/require-default-props */
import propTypes from 'prop-types';
import dynamic from 'next/dynamic';

const Core = dynamic(() => import('@core_modules/cart/plugin/ShoppingBag/core'), { ssr: false });
const WihtLinkView = dynamic(() => import('@core_modules/cart/plugin/ShoppingBag/components/WithLink'), { ssr: false });
const WithoutLinkView = dynamic(() => import('@core_modules/cart/plugin/ShoppingBag/components/WithoutLink'), { ssr: false });

const ShoppingBag = (props) => (
    <Core
        WihtLinkView={WihtLinkView}
        WithoutLinkView={WithoutLinkView}
        {...props}
    />
);

ShoppingBag.propTypes = {
    withLink: propTypes.bool,
};

export default ShoppingBag;
