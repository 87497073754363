/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const categories = gql`
    {
        categoryList {
            children_count
            children {
                id
                level
                name
                path
                url_path
                url_key
                include_in_menu
                children {
                    id
                    level
                    name
                    path
                    url_path
                    url_key
                    image
                    image_path
                    children {
                        id
                        level
                        name
                        path
                        url_path
                        url_key
                        children {
                            id
                            level
                            name
                            path
                            url_path
                            url_key
                        }
                    }
                }
            }
        }
    }
`;

export const getCmsBlocks = gql`
    query($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;

export const getCustomer = gql`
    {
        customer {
            id
            firstname
            lastname
            email
        }
        wishlist {
            items {
                id
            }
        }
    }
`;

export const removeToken = gql`
    mutation {
        internalDeleteCustomerToken {
            result
        }
    }
`;

export const vesMenu = gql`
    query getVesMenu($alias: String!) {
        vesMenu(alias: $alias) {
            menu_id
            name
            items {
                id
                name
                link
                content_type
                link_type
                link_target
                category_id
                menu_id
                hover_icon_url
                icon_url
                banner_url
                children {
                    id
                    name
                    link
                    link_type
                    link_target
                    category_id
                    children {
                        id
                        name
                        link
                        link_type
                        link_target
                        category_id
                        children {
                            id
                            name
                            link
                            link_type
                        }
                    }
                }
            }
        }
    }
`;

/**
 * scema dynamic resolver url
 * @param url String
 * @returns grapql query
 */

export const getProduct = (key) => {
    const query = gql`{
        products(
            search: "${key}",
            pageSize: 5
          ) {
            items {
                id
                name
                url_key
                small_image {
                    url
                    label
                }
                price_range{
                    minimum_price {
                        final_price{
                            currency
                            value
                        }
                    }
                }
            }
            total_count
          }
    }`;
    return query;
};

/**
 * schema dynamic resolver url
 * @param name String
 * @returns graphql query
 */

export const getCategoryByName = (name) => {
    const query = gql`{
        categoryList(filters: { name: { match: "${name}" } }) {
            id
            name
            url_key
            url_path
            __typename
            breadcrumbs {
                category_id
                category_level
                category_name
                category_url_key
                category_url_path
            }
        }
      }`;
    return query;
};

export const getSearchAmoeba = (key) => {
    const query = gql`query searchAmoeba (
        $isLogged: Boolean
    ){
        search(
            search: "${key}"
            data_from: 0
            data_size: 5
            isLogged:  $isLogged
        ) {
            category {
                id
                name
                url_key
                url_path
            }
            product {
                id
                name
                url_key
                image
                stock_status
                price{
                    price
                    original_price
                    is_discount
                    customer_group_id
                    tax_class_id
                    final_price
                    min_price
                    max_price
                }
            }
        }
    }`;
    return query;
};

/**
 * [SCHEMA] get currency
 * @param null
 * @return {graphql}
 */
export const getCurrencySchema = gql`
    {
        currency {
            base_currency_code
            base_currency_symbol
            default_display_currency_code
            default_display_currency_symbol
            available_currency_codes
            exchange_rates {
                currency_to
                rate
            }
        }
    }
`;

export const addHitAffiliate = gql`
    mutation addHitAffiliate($aw_affiliate: String!){
        addHitAffiliate(aw_affiliate: $aw_affiliate){
            hit_id
        }
    }
`;

export const getCountCart = gql`
    query getCartData($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            total_quantity
        }
    }
`;

export const getCountTempCart = gql`
    query getTempCartData($cartId: String!) {
        tempCart(cart_id: $cartId) {
            id
            total_quantity
        }
    }
`;

export const getAmoebaResult = gql`
    query getAmoebaResult($search: String!, $isLogged: Boolean) {
        search(
            search: $search,
            data_from: 0,
            data_size: 5,
            isLogged:  $isLogged
        ) {
            category {
                id
                name
                url_key
                url_path
            }
            product {
                id
                name
                url_key
                image
                stock_status
                price{
                    price
                    original_price
                    is_discount
                    customer_group_id
                    tax_class_id
                    final_price
                    min_price
                    max_price
                }
            }
        }
    }
`;

export const getCategoryResult = gql`
    query getCategoryResult($match: String!) {
        categoryList(filters: { name: { match: $match } }) {
            id
            name
            url_key
            url_path
            __typename
            breadcrumbs {
                category_id
                category_level
                category_name
                category_url_key
                category_url_path
            }
        }
    }
`;

export const getProductResult = gql`
    query getProductResult($search: String!) {
        products(
            search: $search,
            pageSize: 5
          ) {
            items {
                id
                name
                url_key
                small_image {
                    url
                    label
                }
                price_range{
                    minimum_price {
                        final_price{
                            currency
                            value
                        }
                    }
                }
            }
            total_count
        }
    }
`;

export default {
    categories,
    getCustomer,
    removeToken,
    vesMenu,
    getCurrencySchema,
    getProduct,
    getCategoryByName,
    getSearchAmoeba,
    addHitAffiliate,
    getCountCart,
    getCountTempCart,
};
