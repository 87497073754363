/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import TextField from '@material-ui/core/TextField';
// import SearchIcon from '@material-ui/icons/Search';
// import NotificationBell from '@core_modules/notification/plugins/NotificationBell';
// import IconButton from '@material-ui/core/IconButton';
import Link from 'next/link';
// import DesktopInstallApp from '@core_modules/theme/components/custom-install-popup/desktop';
// import { features } from '@config';
import dynamic from 'next/dynamic';
import style from './style';

const ShoppingBagIcon = dynamic(() => import('@core_modules/cart/plugin/ShoppingBag'));
const Menu = dynamic(() => import('@core_modules/theme/components/header/desktop/components/mcategory'));
const TopMenu = dynamic(() => import('@core_modules/theme/components/header/desktop/components/mtop'));
const Autocomplete = dynamic(() => import('@core_modules/theme/components/header/desktop/components/autocomplete'));
const OptionAutocomplete = dynamic(() => import('@core_modules/theme/components/header/desktop/components/autocomplete/view'));
const WidgetThumborImage = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-thumbor-image'));

const ViewTopNavigation = (props) => {
    const {
        storeConfig, handleSearch, value, setValue, data, loading, t, isLogin, customer, handleLogout, app_cookies,
        // searchByClick, value,
    } = props;
    return (
        <div id="header">
            <div className="row header-top hidden-mobile">
                <main style={{ width: '100%' }}>
                    {/* {features.customInstallApp.enabled ? <DesktopInstallApp /> : null} */}
                    <TopMenu t={t} isLogin={isLogin} data={customer} handleLogout={handleLogout} app_cookies={app_cookies} />
                </main>
            </div>
            <main className="header-main-container">
                <div className="header-main">
                    <div className="header-middle">
                        <div className="header-middle__left">
                            <div className="menu-category">
                                <div className="box header-middle__logo">
                                    <Link href="/" prefetch={false}>
                                        <a>
                                            <WidgetThumborImage
                                                width="48"
                                                height="48"
                                                alt="9to9"
                                                src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                                preload
                                            />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="header-middle__center">
                            <div className="header-middle__search">
                                <Autocomplete
                                    value={value}
                                    setValue={setValue}
                                    handleSearch={handleSearch}
                                    OptionsItem={OptionAutocomplete}
                                    storeConfig={storeConfig}
                                />
                                <div className="search-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.81" height="13.81" viewBox="0 0 13.81 13.81">
                                        <g id="Group_15" data-name="Group 15" transform="translate(-405.683 -77.932)">
                                            <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="5.082" cy="5.254" rx="5.082" ry="5.254" transform="translate(406.433 78.682)" fill="none" stroke="#ff6a00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Line_1" data-name="Line 1" x1="3.143" y1="3.264" transform="translate(415.29 87.418)" fill="none" stroke="#ff6a00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="header-middle__right hidden-mobile">
                            {/* <div className="header-middle__item">
                                <div className="header-middle__icon">
                                    <NotificationBell withLink storeConfig={storeConfig} />
                                </div>
                            </div> */}
                            <div className="header-middle__item hidden-mobile">
                                <div className="header-middle__icon">
                                    <Link href={isLogin ? '/wishlist' : '/customer/account/login'} prefetch={false}>
                                        <a>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22.209" height="20.189" viewBox="0 0 22.209 20.189">
                                                <path id="Path_38" data-name="Path 38" d="M785.746,75.554a5.211,5.211,0,0,0-4.764,3.465,5.209,5.209,0,0,0-4.763-3.465c-3.609,0-5.341,3.9-5.341,5.775,0,3.89,10.1,12.414,10.1,12.414s10.1-8.524,10.1-12.414C791.087,79.452,789.355,75.554,785.746,75.554Z" transform="translate(-769.878 -74.554)" fill="none" stroke="#1a1818" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </svg>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="header-middle__item hidden-mobile">
                                <div className="header-middle__icon">
                                    <Link href="/checkout/cart" prefetch={false}>
                                        <a>
                                            <ShoppingBagIcon storeConfig={storeConfig} />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="header-middle__item hidden-mobile">
                                <div className="header-middle__line" />
                            </div>
                            <div className="header-middle__item hidden-mobile">
                                <div className="header-middle__icon">
                                    <Link href="/customer/account" prefetch={false}>
                                        <a>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                                <g id="user" transform="translate(-906 -87)">
                                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="17" cy="17" r="17" transform="translate(906 87)" fill="#ff6a00" />
                                                    <g id="Group_37" data-name="Group 37" transform="translate(913.632 91.883)">
                                                        <path id="Path_37" data-name="Path 37" d="M861.961,93.986h-9.185a3.473,3.473,0,0,1,1.438-4.952,14.407,14.407,0,0,1,7.747-1.741c2-.021,8.16,1.14,8.76,3.316a5.027,5.027,0,0,1,0,3.377Z" transform="translate(-852.252 -73.252)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="4.896" cy="4.896" r="4.896" transform="translate(4.472)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </Link>
                                </div>
                                <div className="header-middle__account-name">
                                    <Link href="/customer/account" prefetch={false}>
                                        <a className="header-middle__text avenir-medium">
                                            {
                                                customer?.customer
                                                    ? (
                                                        `HI, ${customer?.customer?.firstname}`
                                                    ) : (
                                                        'HI, GUEST'
                                                    )
                                            }
                                        </a>
                                    </Link>
                                    {
                                        isLogin ? (
                                            <ul className="header-middle__dropdown">
                                                <li className="header-middle__dropdown-list">
                                                    <a onClick={handleLogout}>
                                                        {t('common:menu:signout')}
                                                    </a>
                                                </li>
                                            </ul>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-vesmenu hidden-mobile">
                        {loading ? null : <Menu data={data} storeConfig={storeConfig} />}
                    </div>
                </div>
            </main>
            <style jsx>
                {`
                    .header-main-container {
                        background: #FFFFFF;
                        width: 100%;
                    }
                    .header-main {
                        max-width: 1440px;
                        width: 100%;
                        margin: 0 auto;
                        padding: 24px 18px 10px 18px;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                    }
                    .header-vesmenu {
                        width: 100%;
                        margin-top: 24px;
                    }
                    @media (min-width: 768px) {
                        #header {
                            position: fixed;
                            width: 100%;
                            background: white;
                            z-index: 3;
                            top: 0;
                            transition: top 1s ease;
                        }
                        .header-top-hide {
                            top: -50px !important;
                        }
                        .header-middle__center {
                            padding: 0 16px;
                        }
                        .header-main {
                            margin-bottom: 6px;
                        }
                    }
                    @media (max-width: 767px) {
                        .header-main-container {
                            background: #fff;
                            box-shadow: '0 0 3px #0000001A',
                        }
                        .header-main {
                            padding: 8px 16px;
                        }
                        .search-icon {
                            left: 14px !important;
                        }
                    }
                    .header-top {
                        display: flex;
                        align-items: center;
                        margin: 0;
                        background: #040505;
                    }
                    .header-middle {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .header-middle__left {
                        flex: 0 0 48px;
                    }
                    .header-middle > * + * {
                        margin-left: 16px;
                    }
                    .header-middle__center {
                        flex: 0 1 765px;
                    }
                    .header-middle__search {
                        width: 100%;
                        position: relative;
                    }
                    .search-icon {
                        position: absolute;
                        top: 9px;
                        left: 32px;
                        width: 12px;
                        height: auto;
                    }
                    .search-icon svg, .search-icon svg * {
                        stroke: #2E2D2B;
                    }
                    .header-middle__right {
                        flex: 0 0 auto;
                        display: flex;
                        align-items: center;
                    }
                    .header-middle__right > * + * {
                        margin-left: 18px;
                    }
                    .header-middle__item {
                        display: flex;
                        align-items: center;
                    }
                    .header-middle__item > * + * {
                        margin-left: 8px;
                    }
                    .header-middle__icon {
                        margin-top: 4px;
                    }
                    .header-middle__line {
                        width: 1px;
                        height: 34px;
                        background: #1A1818;
                    }
                    .header-middle__account-name {
                        position: relative;
                        min-height: 30px;
                        display: flex;
                        align-items: center;
                    }
                    .header-middle__account-name:hover .header-middle__dropdown {
                        display: block;
                    }
                    .header-middle__text {
                        font-size: 12px;
                        text-transform: uppercase;
                    }
                    .header-middle__text:hover {
                        text-decoration: underline;
                    }
                    .header-middle__dropdown {
                        display: none;
                        position: absolute;
                        list-style: none;
                        margin: 0;
                        width: auto;
                        right: 0;
                        top: 24px;
                        background: #fff;
                        padding: 12px 18px;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                    }
                    .header-middle__dropdown-list {
                        text-transform: uppercase;
                        font-size: 11px;
                        white-space: nowrap;
                    }
                    .header-middle__dropdown-list:hover {
                        opacity: 0.8;
                    }
                `}
            </style>
        </div>
    );
};

export default ViewTopNavigation;
