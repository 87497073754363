/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
/* eslint-disable radix */

import { gql } from '@apollo/client';
import { modules } from '@config';
/**
 * generate dynamic filter query
 * @param catId number
 * @param filter array of filter value
 * @returns string query to generate on grapql tag
 */
const filterProduct = (filter) => {
    let queryFilter = '{ ';
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < filter.length; index++) {
        const detailFilter = filter[index];
        if (detailFilter.type === 'price') {
            queryFilter += `
          ,${detailFilter.type} : {
            from: "${detailFilter.from}"
            to: "${detailFilter.to}"
          }
        `;
        } else if (detailFilter.type === 'discount_filter') {
            let inFilterDiscount = '';
            // eslint-disable-next-line no-plusplus
            for (let idx = 0; idx < detailFilter.value.length; idx++) {
                inFilterDiscount += `${idx !== 0 ? ',' : ''}"${detailFilter.value[idx]}"`;
            }
            queryFilter += `${index !== 0 ? ',' : ''} ${detailFilter.type} : {
              match: ${inFilterDiscount}
            }`;
        } else if (typeof detailFilter.value === 'object') {
            let inFilter = '';
            // eslint-disable-next-line no-plusplus
            for (let idx = 0; idx < detailFilter.value.length; idx++) {
                inFilter += `${idx !== 0 ? ',' : ''}"${detailFilter.value[idx]}"`;
            }
            queryFilter += `${index !== 0 ? ',' : ''} ${detailFilter.type} : {
                in: [${inFilter}]
              }`;
        } else {
            queryFilter += `${index !== 0 ? ',' : ''} ${detailFilter.type} : {
                  eq: "${detailFilter.value}"
                }`;
        }
    }
    queryFilter += '}';
    return queryFilter;
};

const amoebaFilterProduct = (filter) => {
    let queryFilter = '';

    for (let index = 0; index < filter.length; index++) {
        const detailFilter = filter[index];

        if (detailFilter.type === 'price') {
            queryFilter += `
              ${detailFilter.type}: {
                value_range: {
                  min: ${parseInt(detailFilter.from)}
                  max: ${parseInt(detailFilter.to)}
                }
              }
            `;
        }

        if (detailFilter.type === 'gender'
          || detailFilter.type === 'brand'
          || detailFilter.type === 'color'
          || detailFilter.type === 'size'
          || detailFilter.type === 'type'
        ) {
            const { type } = detailFilter;
            let { value: tmpFilterValue } = detailFilter;
            if (typeof tmpFilterValue === 'string' && tmpFilterValue.length > 0) {
                tmpFilterValue = tmpFilterValue.split(',');
            }

            const inFilter = [];
            for (let idx = 0; idx < tmpFilterValue.length; idx++) {
                inFilter.push(tmpFilterValue[idx]);
            }
            queryFilter += `${type}: ${JSON.stringify(inFilter)},`;
        }
        if (detailFilter.type === 'discount_filter') {
            const typeDiscont = detailFilter.type;
            const valueDiscount = detailFilter.value[0];
            queryFilter += `${typeDiscont}: ${valueDiscount},`;
        }
        if (detailFilter.type === 'style') {
            const typeStyle = detailFilter.type;
            const valueStyle = detailFilter.value;
            queryFilter += `${typeStyle}: ["${valueStyle}"],`;
        }
    }

    return queryFilter;
};

const getCategoryIdFilter = (config) => {
    let categoryFilter = '';
    const arrayFilter = config.filter;

    for (let index = 0; index < arrayFilter.length; index++) {
        const filteredArray = arrayFilter[index];
        if (filteredArray.type === 'category_id') {
            categoryFilter = `filter: {category_id: "${filteredArray.value}"},`;
        }
    }
    return categoryFilter;
};

const amoebaSort = (config) => {
    let sortResult = '';

    if (config.sort.key === 'discount_filter') {
    // if (config.sort.value === 'ASC') {
    // sortResult = `${config.sort.key} : DESC`;
        sortResult = `discount : ${config.sort.value}`;
    // } else {
    // sortResult = `${config.sort.key} : ASC`;
    //   sortResult = 'discount : ASC';
    // }
    } else {
        sortResult = `${config.sort.key} : ${config.sort.value}`;
    }

    return sortResult;
};

export const getProductAggregations = () => gql`
    query getProductAggregations($filter: ProductAttributeFilterInput) {
        products(search: "", filter: $filter) {
            aggregations {
                attribute_code
            }
        }
    }
`;

/**
 * scema dynamic product
 * @param catId number
 * @param config Object {pageSize: number, currentPage: Number}
 * @returns grapql query
 */

export const getProduct = (config = {}) => gql`
  query getProducts(
    $pageSize: Int,
    $currentPage: Int,
  ){
  products( search: "${config.search}" ,filter: ${filterProduct(config.filter)},
  pageSize: $pageSize,
  currentPage: $currentPage
  ${config.sort && config.sort.key && config.sort.key !== 'position'
        ? `, sort: {${config.sort.key} : ${config.sort.value}}`
        : ''
}
    ) {
      total_count
      ${!config.customFilter
        ? `aggregations {
        attribute_code
        label
        options {
          count
          label
          value
        }
      }` : ''}
      __typename
      items {
        id
        sku
        name
        url_key
        ${modules.catalog.productListing.configurableOptions ? `review {
          rating_summary
          reviews_count
        }` : ''}
        small_image {
          url,
          label
        }
        additional_data {
          sold_qty
          label
        }
        weltpixel_labels {
          categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color          
          }
          productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color  
          }
        }
        categories {
          name
        }
        __typename
        price_tiers {
          discount {
            percent_off
            amount_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        price_range {
          maximum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }

        special_from_date
        special_to_date      
        max_discount {
          amount_off
          percent_off
        }
        new_from_date
        new_to_date
        ${modules.catalog.productListing.label.sale.enabled ? 'sale' : ''}
        ... on ConfigurableProduct {
          variants {
              product {
                  image{
                    url
                  }
              }
              attributes {
                  label
                  code
                  value_index
              }
          }
        }
      }
    }
  }
`;

export const getAggregationAmoebaSchema = (config = {}) => gql`
  query getProducts(
    $pageSize: Int,
    $currentPage: Int,
  ){
  products( search: "${config.search}" ,filter: ${filterProduct(config.filter)},
  pageSize: $pageSize,
  currentPage: $currentPage
  ${config.sort && config.sort.key && config.sort.key !== 'position'
        ? `, sort: {${config.sort.key} : ${config.sort.value}}`
        : ''
}
    ) {
      total_count
      ${!config.customFilter
        ? `aggregations {
        attribute_code
        label
        options {
          count
          label
          value
        }
      }` : ''}
      __typename
    }
  }
`;

export const getMagentoPriceSchema = (sku) => {
    const query = gql`{
    products(
      search: "" ,filter: {
        sku: {
          eq: "${sku}"
        }
      }
    ) {
      items {
        additional_data {
          sold_qty
          label
        }
        weltpixel_labels {
          categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color          
          }
          productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color  
          }
        }
        review {
          rating_summary
          reviews_count
        }
        __typename
        price_tiers {
          discount {
            percent_off
            amount_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        price_range {
          maximum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }

        special_from_date
        special_to_date      
        max_discount {
          amount_off
          percent_off
        }
      }
    }
  }`;
    return query;
};

export const amoebaProductsByCategory = (config = {}) => gql`
    query getAmoebaProducts(
      $from: Int
      $size: Int
      $isLogged: Boolean
    ) {
      categories(
        withProduct: {
          enabled: true
          data_product_search: "${config.search}"
          data_product_from: $from
          data_product_size: $size
          data_filter: {
            is_in_stock: true
            ${amoebaFilterProduct(config.filter)}
          } 
          ${config.sort && config.sort.key && config.sort.key !== 'position'
        ? `, data_sort: {${amoebaSort(config)}}`
        : ''
}
        }
        ${getCategoryIdFilter(config)}
        isLogged: $isLogged
      ) {
          id
          name
          is_active
          url_key
          total_product
          products {
              id
              type_id
              name
              url_key
              sku
              image
              description
              short_description
              price_discount
              price {
                  price
                  original_price
                  is_discount
                  customer_group_id
                  tax_class_id
                  final_price
                  min_price
                  max_price
              }
              category {
                  category_id
                  is_virtual
                  is_parent
                  name
              }
          }
      }
    }
  `;

export const bulkSkuMagento = gql`
  query getBulkData($bulkSku: [String]){
    products(
      pageSize: 10000
      filter: {
        sku: {
          in: $bulkSku
        }
      }
    ) {
      items {
        sku
        weltpixel_labels {
          categoryLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color          
          }
          productLabel {
            css
            customer_group
            image
            page_position
            position
            priority
            text
            text_padding
            text_bg_color
            text_font_size
            text_font_color  
          }
        }
        additional_data {
          sold_qty
          label
        }
        review {
          rating_summary
          reviews_count
        }
        __typename
        price_tiers {
          discount {
            percent_off
            amount_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        price_range {
          maximum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            discount{
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        special_from_date
        special_to_date      
        max_discount {
          amount_off
          percent_off
        }
        ... on ConfigurableProduct {
          variants {
            product {
              image{
                url
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const addWishlist = gql`
    mutation addWishlist($productId: Int!) {
        addProductToWishlist(productId: $productId) {
            info
        }
    }
`;

export const bulkSkuMagentoQuery = gql`
  query getBulkData($bulkSku: [String]){
    customProductSearch(bulk_sku: $bulkSku) {
      sku
      weltpixel_labels {
        categoryLabel {
          css
          customer_group
          image
          page_position
          position
          priority
          text
          text_padding
          text_bg_color
          text_font_size
          text_font_color          
        }
        productLabel {
          css
          customer_group
          image
          page_position
          position
          priority
          text
          text_padding
          text_bg_color
          text_font_size
          text_font_color  
        }
      }
      additional_data {
        sold_qty
        label
      }
      review {
        rating_summary
        reviews_count
      }
      __typename
      price_tiers {
        discount {
          percent_off
          amount_off
        }
        final_price {
          currency
          value
        }
        quantity
      }
      price_range {
        maximum_price {
          discount{
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          discount{
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
      }
      special_from_date
      special_to_date      
      max_discount {
        amount_off
        percent_off
      }
      ... on ConfigurableProduct {
        variants {
          product {
            image{
              url
            }
          }
          attributes {
            label
            code
            value_index
          }
        }
      }
    }
  }
`;
