import makeStyles from '@material-ui/core/styles/makeStyles';
import { FlexColumn, FlexRow } from '@theme_mixins';

const useStyles = makeStyles({
    root: {
        margin: 0,
        marginBottom: 10,
        ...FlexColumn,
    },
    column: {
        ...FlexColumn,
    },
    row: {
        ...FlexRow,
    },
    error: {
        marginBottom: 0,
    },
    showMore: {
        marginTop: '16px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        color: '#FF6A00',
        fontSize: '18px',
    },
    showMoreText: {
        fontSize: '12px',
        color: '#FF6A00',
    },
});

export default useStyles;
