/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
import React from 'react';
import dynamic from 'next/dynamic';

import { modules } from '@config';
import { getCategoryProducts, getAmoebaCategoryProducts } from '@core_modules/catalog/services/graphql';
import { useBulkQuery } from '@core_modules/catalog/helpers/useBulkQuery';

const Carousel = dynamic(() => import('@common_slick/Caraousel'));
const GridList = dynamic(() => import('@common_gridlist'));
const ProductItem = dynamic(() => import('@core_modules/catalog/plugin/ProductItem'));
const SkeletonWidget = dynamic(() => import('@common_slick/Caraousel/Skeleton'));
/**
 * [COMBINE] data condition for better format
 * @param {json} condition_object
 * @returns
 */
const getDataCondition = ({ condition_object }) => {
    let conditions = {};
    for (const condition_index in condition_object) {
        const condition_item = condition_object[condition_index];
        const attribute = condition_item?.attribute;
        const aggregator = condition_item?.aggregator;
        if (attribute !== undefined) conditions = { ...conditions, attribute, attribute_value: condition_item?.value };
        if (aggregator !== undefined) conditions = { ...conditions, aggregator, aggregator_value: condition_item?.value };
    }
    return conditions;
};

/**
 * [CONSTANT] variable
 */
const TEMPLATE_SLIDER = 'slider';
const TEMPLATE_GRID = 'grid';

const WidgetListProduct = (props) => {
    const {
        template,
        products_count,
        conditions_encoded,
        parentClass,
        storeConfig,
    } = props;
    const condition_object = JSON.parse(conditions_encoded);
    const dataCondition = getDataCondition({ condition_object });
    const page = 1;
    const amoebaPage = 0;

    let dataLoading = true;
    let dataItems = [];
    let bulkData = [];
    const isAmoeba = storeConfig && storeConfig.amoeba;
    /**
     * [QUERY] query for products items
     */
    if (dataCondition.attribute === 'category_ids') {
        if (isAmoeba) {
            const { data, loading } = getAmoebaCategoryProducts({ category_id: dataCondition.attribute_value, products_count, page: amoebaPage });
            dataLoading = loading;
            dataItems = data && data.categories && data.categories.length > 0
                ? data.categories[0].products : [];
        } else {
            const { data, loading } = getCategoryProducts({ category_id: dataCondition.attribute_value, products_count, page });
            dataLoading = loading;
            dataItems = data?.categoryList[0]?.products?.items;
        }
    }
    if (typeof window !== 'undefined' && isAmoeba) {
        const productSku = dataItems
            && dataItems.length > 0
            ? dataItems.map((product) => product.sku)
            : [];
        const data = useBulkQuery(productSku);
        bulkData = data;
    }

    /**
     * [METHOD] on reinit trigger when all data has been rendered, hide skeleton
     */
    const onReInit = () => {
        if (document.getElementsByClassName('widget-product-list')) {
            const elms = document.getElementsByClassName('widget-product-list');
            for (let i = 0; i < elms.length; i++) {
                elms[i].className = 'full-width widget-product-list';
            }
        }
        if (document.getElementsByClassName('widget-product-list-skeleton')) {
            const elms = document.getElementsByClassName('widget-product-list-skeleton');
            for (let i = 0; i < elms.length; i++) {
                elms[i].className = 'full-width widget-product-list-skeleton hide';
            }
        }
    };

    /**
     * [TEMPLATE] type slider
     */
    const classSkeleton = typeof window === 'undefined' ? 'full-width widget-product-list-skeleton' : 'full-width widget-product-list-skeleton hide';
    const classProductList = typeof window === 'undefined' ? 'full-width widget-product-list hide' : 'full-width widget-product-list';
    if (template === TEMPLATE_SLIDER && !dataLoading && dataItems?.length > 0) {
        return (
            <>
                <div className={classSkeleton}>
                    <SkeletonWidget />
                </div>
                <div className={classProductList}>
                    <Carousel
                        isAmoeba={isAmoeba}
                        bulkData={bulkData}
                        onReInit={onReInit}
                        data={dataItems}
                        Item={ProductItem}
                        slideLg={(dataItems?.length > 4 && parentClass === 'pwa-product-slider-full') ? 5 : 4}
                        slideMd={dataItems?.length > 4 ? 4 : 3}
                    />
                </div>
            </>
        );
    }

    /**
     * [TEMPLATE] type grid
     */
    if (template === TEMPLATE_GRID && !dataLoading && dataItems?.length > 0) {
        return (
            <>
                <div className={classSkeleton}>
                    <SkeletonWidget />
                </div>
                <div className={classProductList}>
                    <GridList
                        isAmoeba={isAmoeba}
                        bulkData={bulkData}
                        data={dataItems}
                        ItemComponent={ProductItem}
                        className="grid"
                        gridItemProps={{ xs: 6, sm: 4, md: modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 3 : 2 }}
                    />
                </div>
            </>
        );
    }

    /**
     * [TEMPLATE] not found
     */
    if (!dataLoading && dataItems?.length < 1) {
        return (
            <div>
                our widget still not supported for
                {' '}
                <strong>{dataCondition.attribute}</strong>
            </div>
        );
    }

    return <SkeletonWidget />;
};

export default WidgetListProduct;
