import Alert from '@material-ui/lab/Alert';
import dynamic from 'next/dynamic';

const CmsRenderer = dynamic(() => import('@core_modules/cms/components/cms-renderer'), { ssr: true });
const Loading = dynamic(() => import('@common_loaders/Backdrop'), { ssr: false });

const CmsPage = (props) => {
    const {
        data, t, loading, error, storeConfig, onlyCms,
    } = props;
    if (error) {
        return (
            <Alert className="m-15" severity="error">
                {t('common:error:fetchError')}
            </Alert>
        );
    }

    if (loading) return <Loading open={loading} />;
    if (onlyCms) return <CmsRenderer type="page" content={data.cmsPage.content} storeConfig={storeConfig} />;
    return (
        <div className="cms-container">
            <CmsRenderer type="page" content={data.cmsPage.content} storeConfig={storeConfig} />
        </div>
    );
};

export default CmsPage;
