import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from '@core_modules/product/services/graphql/schema';
import * as ActionSchema from '@core_modules/product/services/graphql/actionSchema';

export const getProduct = (urlpath) => useQuery(Schema.getProduct(urlpath), {
    errorPolicy: 'ignore',
});

export const getProductBySku = (params) => useQuery(Schema.getProductBySku(), {
    ...params,
});

export const getProductBySkuFreeItems = (params) => useQuery(Schema.getProductBySkuFreeItems(), {
    ...params,
});

export const getReviews = (params) => useQuery(Schema.getReview(), {
    variables: {
        ...params,
    },
});

export const addReview = () => useMutation(Schema.addReview, {
    context: {
        request: 'internal',
    },
});

export const addWishlist = () => useMutation(Schema.addWishlist, {
    context: {
        request: 'internal',
    },
});

export const getConfigurableProduct = (sku) => useQuery(Schema.getConfigurableProduct(sku));
export const getBundleProduct = (sku) => useQuery(Schema.getBundleProduct(sku));
export const getDownloadroduct = (sku) => useQuery(Schema.getDownloadProduct(sku));
export const getGroupedProduct = (sku) => useQuery(Schema.getGroupedProduct, {
    variables: { sku },
    skip: !sku,
});

// actions add to cart

export const addSimpleProductsToCart = () => useMutation(ActionSchema.addSimpleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addVirtualProductToCart = () => useMutation(ActionSchema.addVirtualProductToCart, {
    context: {
        request: 'internal',
    },
});

export const addDownloadProductToCart = () => useMutation(ActionSchema.addDownloadableProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addConfigProductsToCart = () => useMutation(ActionSchema.addConfigProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addBundleProductsToCart = () => useMutation(ActionSchema.addBundleProductsToCart, {
    context: {
        request: 'internal',
    },
});

// magento cart
export const getGuestCartId = () => useMutation(ActionSchema.createCartIdGuest);
export const getCustomerCartId = () => useLazyQuery(ActionSchema.getCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
});
// end magento cart

// temp cart
export const getGuestTempCartId = () => useMutation(ActionSchema.createTempCartIdGuest);
export const getCustomerTempCartId = () => useLazyQuery(ActionSchema.getTempCartIdUser, {
    context: {
        request: 'internal',
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'no-cache',
});
// end temp cart

export const addProductsToCart = () => useMutation(ActionSchema.addProductToCart, {
    context: {
        request: 'internal',
    },
});

export const getUpsellProducts = (urlpath) => useQuery(Schema.getUpsellProducts(urlpath), {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
});

export const getRelatedProducts = (urlpath) => useQuery(Schema.getRelatedProducts(urlpath), {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
});

// add product to temp cart
export const addProductsToTempCart = () => useMutation(ActionSchema.addProductsToTempCart, {
    context: {
        request: 'internal',
    },
});
// end add product to temp cart

export const getProductVariants = (sku) => useQuery(Schema.getProductVariants(sku));

export const getProductQuery = (urlpath) => useQuery(Schema.getProductQuery(urlpath), {
    errorPolicy: 'ignore',
});

export const getUpsellProductsQuery = (urlpath) => useQuery(Schema.getUpsellProductsQuery(urlpath), {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
});

export const getRelatedProductsQuery = (urlpath) => useQuery(Schema.getRelatedProductsQuery(urlpath), {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
});

export const getConfigurableProductQuery = (urlpath) => useQuery(Schema.getConfigurableProductQuery(urlpath), {
    errorPolicy: 'ignore',
});

export const getProductVariantsQuery = (urlpath) => useQuery(Schema.getProductVariantsQuery(urlpath));

export default {
    getProduct,
    getProductQuery,
    getUpsellProductsQuery,
    getRelatedProductsQuery,
    getConfigurableProductQuery,
};
