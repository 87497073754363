/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { removeIsLoginFlagging } from '@helper_auth';
import { removeCartId, removeTempCartId } from '@helper_cartid';
import Cookies from 'js-cookie';
import { useApolloClient } from '@apollo/client';
import { localTotalCart } from '@services/graphql/schema/local';
import firebase from 'firebase/app';
import { custDataNameCookie, features } from '@config';
import {
    getCategories, removeToken, getVesMenu, addHitAffiliate,
} from '@core_modules/theme/services/graphql';
import {
    getCustomer,
} from '@core_modules/theme/services/graphql/schema';
import dynamic from 'next/dynamic';

const Content = dynamic(() => import('@core_modules/theme/components/header/desktop/components'));

const CoreTopNavigation = (props) => {
    const router = useRouter();
    const client = useApolloClient();
    const {
        storeConfig, t, app_cookies, isLogin,
    } = props;

    const [value, setValue] = React.useState('');
    const [customerData, setCustomerData] = React.useState({});
    const [deleteTokenGql] = removeToken();

    const [getHitAffiliate] = addHitAffiliate();
    const { data, loading } = features.vesMenu.enabled
        ? getVesMenu({
            variables: {
                alias: features.vesMenu.alias,
            },
        })
        : getCategories();

    const handleLogout = () => {
        deleteTokenGql()
            .then(() => {
                Cookies.remove(custDataNameCookie);
                removeIsLoginFlagging();
                removeCartId();
                removeTempCartId();
                if (features.firebase.config.apiKey && features.firebase.config.apiKey !== '') {
                    firebase.auth().signOut().then(() => {
                        // Sign-out successful.
                    }).catch(() => {
                        // An error happened.
                    });
                }
                client.writeQuery({ query: localTotalCart, data: { totalCart: 0 } });
                router.push('/customer/account/login');
            })
            .catch(() => {
                //
            });
    };

    const handleSearch = (ev) => {
        if (ev.key === 'Enter' && ev.target.value !== '') {
            router.push(`/catalogsearch/result?q=${value}`);
        }
    };

    const searchByClick = () => {
        if (value !== '') {
            router.push(`/catalogsearch/result?q=${value}`);
        }
    };

    const query = router && router.query;

    useEffect(() => {
        // get customer info data
        const getCustomerInfo = async () => {
            if (isLogin) {
                const custCookie = Cookies.getJSON(custDataNameCookie);
                if (custCookie) {
                    setCustomerData({ customer: custCookie });
                } else {
                    const { data: resData } = await client.query({
                        query: getCustomer,
                        context: {
                            request: 'internal',
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all',
                    });
                    if (resData) {
                        Cookies.set(custDataNameCookie, resData.customer);
                        setCustomerData(resData);
                    }
                }
            }
        };

        if (typeof window !== 'undefined') {
            getCustomerInfo();
            // aheadworks affiliate, if query aw_affiliate exist
            if (query && query.aw_affiliate) {
                Cookies.set('aw_affiliate', query.aw_affiliate);
                getHitAffiliate({
                    variables: {
                        aw_affiliate: query.aw_affiliate,
                    },
                })
                    .then((res) => {
                        Cookies.set('aw_affiliate_hit_id', res.data.addHitAffiliate.hit_id);
                    })
                    .catch(() => {
                        router.push('/');
                    });
            }
            // end aheadworks affiliate
        }
    }, []);

    return (
        <Content
            t={t}
            isLogin={isLogin}
            data={data}
            loading={loading}
            storeConfig={storeConfig}
            handleSearch={handleSearch}
            searchByClick={searchByClick}
            setValue={setValue}
            customer={customerData}
            handleLogout={handleLogout}
            value={value}
            app_cookies={app_cookies}
        />
    );
};

export default CoreTopNavigation;
