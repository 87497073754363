/* eslint-disable jsx-a11y/anchor-is-valid */
import { modules, debuging } from '@config';
import { getLoginInfo } from '@helper_auth';
import { setCookies } from '@helper_cookies';
import { useTranslation } from '@i18n';
import route from 'next/router';
import React from 'react';
import dynamic from 'next/dynamic';
import { setResolver, getResolver } from '@helper_localstorage';
import classNames from 'classnames';
import { addWishlist } from '@core_modules/catalog/services/graphql';
import useStyles from '@plugin_productitem/style';
import TagManager from 'react-gtm-module';

const ConfigurableOpt = dynamic(() => import('@core_modules/catalog/plugin/OptionItem'));
const ConfigurableDynamic = dynamic(() => import('@core_modules/catalog/plugin/OptionItem'), { ssr: false });
const LabelAmoeba = dynamic(() => import('@plugin_productitem/components/LabelAmoeba'), { ssr: false });
const WeltpixelLabel = dynamic(() => import('@plugin_productitem/components/WeltpixelLabel'), { ssr: false });
const ColorSwatch = dynamic(() => import('@core_modules/product/pages/default/components/ColorSwatch'), { ssr: false });

const ProductItem = (props) => {
    const {
        isAmoeba, id, url_key = '', categorySelect, review, ImageProductView, DetailProductView, LabelView, className = '',
        enableAddToCart, enableOption, additional_data, displayAddToCart, weltpixel_labels, sku, __typename, ...other
    } = props;

    const { bulkData, variants } = props;

    const styles = useStyles();
    const { t } = useTranslation(['catalog', 'common']);
    const [feed, setFeed] = React.useState(false);
    const [spesificProduct, setSpesificProduct] = React.useState({});
    const [selectedColorSwatch, setSelectedColorSwatch] = React.useState({});
    const [selectedImage, setSelectedImage] = React.useState(null);

    React.useEffect(() => {
        if ((selectedColorSwatch?.color && selectedColorSwatch?.image)) {
            setSelectedImage(selectedColorSwatch.image.url);
        } else {
            setSelectedImage(null);
        }
    }, [selectedColorSwatch]);

    let isLogin = '';
    if (typeof window !== 'undefined') isLogin = getLoginInfo();
    const [postAddWishlist] = addWishlist();

    const handleFeed = (itemProps) => {
        if (isLogin && isLogin !== '') {
            postAddWishlist({
                variables: {
                    productId: parseInt(id, 10),
                },
            })
                .then(async () => {
                    // Data Layer GA4
                    TagManager.dataLayer({
                        dataLayer: {
                            ecommerce: {
                                action: {
                                    items: [
                                        {
                                            currency: itemProps.price_range.minimum_price.regular_price.currency,
                                            item_name: itemProps.name,
                                            item_id: itemProps.sku,
                                            price: itemProps.price_range.minimum_price.regular_price.value || 0,
                                            item_category: itemProps.categories.length > 0 ? itemProps.categories[0].name : '',
                                            item_stock_status: itemProps.stock_status,
                                        },
                                    ],
                                },
                            },
                            event: 'add_to_wishlist',
                        },
                    });

                    await setFeed(!feed);
                    await window.toastMessage({ open: true, variant: 'success', text: t('common:message:feedSuccess') });
                    route.push('/wishlist');
                })
                .catch((e) => {
                    window.toastMessage({
                        open: true,
                        variant: 'error',
                        text: debuging.originalError ? e.message.split(':')[1] : t('common:message:feedFailed'),
                    });
                });
        } else if (typeof window.toastMessage !== 'undefined') {
            window.toastMessage({
                open: true,
                variant: 'warning',
                text: t('catalog:wishlist:addWithoutLogin'),
            });
        }
    };

    const handleClick = async (e, itemProps) => {
        e.preventDefault();

        // GA 4 dataLayer
        TagManager.dataLayer({
            dataLayer: {
                event: 'select_item',
                ecommerce: {
                    action: {
                        items: [
                            {
                                currency: itemProps.price_range.minimum_price.regular_price.currency,
                                item_name: itemProps.name,
                                item_id: itemProps.sku,
                                price: itemProps.price_range.minimum_price.regular_price.value || 0,
                                item_category: itemProps.categories.length > 0 ? itemProps.categories[0].name : '',
                                item_stock_status: itemProps.stock_status,
                            },
                        ],
                    },
                },
            },
        });

        const urlResolver = getResolver();
        urlResolver[`/${url_key}`] = {
            type: 'PRODUCT',
        };
        await setResolver(urlResolver);
        setCookies('lastCategory', categorySelect);
        route.push('/[...slug]', `/${url_key}`);
    };

    const ratingValue = review && review.rating_summary ? parseInt(review.rating_summary, 0) / 20 : 0;
    const DetailProps = {
        spesificProduct,
        handleClick,
        handleFeed,
        feed,
        ratingValue,
        additional_data,
    };
    const showAddToCart = typeof enableAddToCart !== 'undefined' ? enableAddToCart : modules.catalog.productListing.addToCart.enabled;
    // const showOption = typeof enableOption !== 'undefined'
    //     ? enableOption : modules.catalog.productListing.configurableOptions.enabled;
    let productVariants = [];
    if (bulkData?.length > 0) {
        // amoeba
        productVariants = bulkData.filter((filtered) => filtered.sku === sku)?.[0]?.variants;
    } else if (variants?.length > 0) {
        // gql magento
        productVariants = variants;
    }

    return (
        <>
            <div className={classNames(styles.itemContainer, className)}>
                <div className={classNames(styles.itemContent)}>
                    {
                        modules.catalog.productListing.label.enabled && LabelView ? (
                            <LabelView
                                t={t}
                                {...other}
                                spesificProduct={spesificProduct}
                                sku={sku}
                                __typename={__typename}
                            />
                        ) : null
                    }
                    <div className={styles.imgItem}>
                        <LabelAmoeba
                            isAmoeba={isAmoeba}
                            t={t}
                            {...DetailProps}
                            {...other}
                            sku={sku}
                            __typename={__typename}
                        />
                        <WeltpixelLabel t={t} weltpixel_labels={weltpixel_labels} categoryLabel />
                        <ImageProductView
                            t={t}
                            handleClick={handleClick}
                            spesificProduct={spesificProduct}
                            url_key={url_key}
                            selectedImage={selectedImage}
                            sku={sku}
                            __typename={__typename}
                            {...other}
                        />
                    </div>
                    {
                        (__typename === 'ConfigurableProduct' && productVariants?.length > 0) && (
                            <ColorSwatch
                                sku={sku}
                                selectedColorSwatch={selectedColorSwatch}
                                setSelectedColorSwatch={setSelectedColorSwatch}
                                pageType="product-list"
                                productVariants={productVariants}
                            />
                        )
                    }
                    <div className={styles.detailItem}>
                        <DetailProductView
                            isAmoeba={isAmoeba}
                            t={t}
                            url_key={url_key}
                            __typename={__typename}
                            sku={sku}
                            {...DetailProps}
                            {...other}
                        />
                        {displayAddToCart
                            ? (
                                <>
                                    {
                                        isAmoeba ? (
                                            <div className={classNames(styles.divOrder, styles.centerPosition, 'btn-place-order-product-list')}>
                                                <ConfigurableDynamic
                                                    enableBundle={false}
                                                    enableDownload={false}
                                                    t={t}
                                                    data={{
                                                        ...other,
                                                        sku,
                                                    }}
                                                    showQty={false}
                                                    handleSelecteProduct={setSpesificProduct}
                                                    showAddToCart={showAddToCart}
                                                    propsItem={{
                                                        className: styles.itemConfigurable,
                                                    }}
                                                    labelAddToCart={t('catalog:button:order')}
                                                    sku={sku}
                                                    __typename={__typename}
                                                    {...other}
                                                />
                                            </div>
                                        ) : (
                                            <div className={classNames(styles.divOrder, styles.centerPosition, 'btn-place-order-product-list')}>
                                                <ConfigurableOpt
                                                    enableBundle={false}
                                                    enableDownload={false}
                                                    t={t}
                                                    data={{
                                                        ...other,
                                                        sku,
                                                    }}
                                                    showQty={false}
                                                    handleSelecteProduct={setSpesificProduct}
                                                    showAddToCart={showAddToCart}
                                                    propsItem={{
                                                        className: styles.itemConfigurable,
                                                    }}
                                                    labelAddToCart={t('catalog:button:order')}
                                                    __typename={__typename}
                                                    {...other}
                                                />
                                            </div>
                                        )
                                    }
                                </>
                            )
                            : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductItem;
