// valid url should be including https:// or http://
const validateUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

const getPath = (href = '') => {
    const is_valid_url = validateUrl(href);
    if (is_valid_url) {
        const checkUrl = new URL(href);
        return checkUrl.pathname.replace('.html', '');
    }

    return href;
};

export default getPath;
