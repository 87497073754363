import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .slick-slider': {
            margin: '0 -10px',
        },
        '& .slick-slide': {
            paddingRight: '10px',
            paddingLeft: '10px',
        },
        [theme.breakpoints.down(767)]: {
            padding: '10px',
        },
    },
}));

export default useStyles;
