import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    containerContainer: {
        position: 'relative',
        width: '210px',
        maxWidth: '100%',
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            margin: '0',
            maxWidth: '100%',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        overflowX: 'auto',
        padding: '12px 0',
        scrollBehavior: 'smooth',
        '&.container-product-list': {
            width: '210px',
            margin: '0 auto',
            maxWidth: '90%',
            padding: '12px 3px 0 3px',
            [theme.breakpoints.up(767)]: {
                '-ms-overflow-style': 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
            [theme.breakpoints.down('xs')]: {
                margin: '0',
                maxWidth: '100%',
            },
        },
        '&.container-product-list-center': {
            [theme.breakpoints.up(767)]: {
                justifyContent: 'center',
            },
        },
    },
    variantItem: {
        position: 'relative',
        background: '#fff',
        borderRadius: '8px',
        flex: '0 0 70px',
        height: '70px',
        cursor: 'pointer',
        overflow: 'hidden',
        marginLeft: '16px',
    },
    overlayEmptyStock: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#000',
        width: '70px',
        height: '70px',
        zIndex: 2,
        opacity: '0.5',
        cursor: 'not-allowed',
    },
    variantItemProductListContainer: {
        flex: '0 0 25%',
        padding: '0 5px',
        [theme.breakpoints.down('xs')]: {
            padding: '0 3px',
        },
    },
    variantItemProductList: {
        position: 'relative',
        background: '#fff',
        borderRadius: '5px',
        flex: '0 0 36px',
        height: '36px',
        cursor: 'pointer',
        overflow: 'hidden',
        border: '1px solid #DEDEDE',
        margin: '0 5px',
        [theme.breakpoints.down('xs')]: {
            margin: '0 3px',
        },
    },
    imageContainer: {
        position: 'absolute',
        inset: 0,
    },
    lastItemIndicator: {
        flex: '0 0 16px',
        height: '16px',
    },
    prevBtn: {
        cursor: 'pointer',
        position: 'absolute',
        top: 'calc(50% + 6px)',
        left: '0',
        transform: 'translateY(-50%) scaleX(-1)',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    nextBtn: {
        cursor: 'pointer',
        position: 'absolute',
        top: 'calc(50% + 6px)',
        right: '0',
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));

export default useStyles;
