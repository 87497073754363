import makeStyles from '@material-ui/core/styles/makeStyles';
import { PRIMARY, WHITE } from '@theme_color';

export default makeStyles((theme) => ({
    container: {},
    itemContainer: {
        padding: '0 18px',
        [theme.breakpoints.down(768)]: {
            padding: '0 8px',
        },
    },
    itemContent: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        '& .MuiSkeleton-rect': {
            paddingBottom: '120%',
        },
        '& a': {
            cursor: 'pointer',
        },
        '& .MuiButton-root': {
            borderRadius: 8,
            fontSize: 12,
            width: 91,
            height: 38,
        },
        [theme.breakpoints.up(768)]: {
            // '& .btn-place-order-product-list': {
            //     display: 'none',
            // },
            '&:hover': {
                background: '#FFFFFF',
                boxShadow: '0px 3px 13px #00000029',
                borderRadius: '4px',
                transition: 'box-shadow 0.6s ease-in-out',
                height: '100%',
                // '& .btn-place-order-product-list': {
                //     display: 'block',
                //     transition: 'opacity 0.8s ease-in-out',
                // },
            },
        },
    },
    badgesNewSales: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 5,
        borderRadius: 5,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 15,
    },
    imgItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '& .btn-quick-view': {
            position: 'absolute',
            zIndex: 2,
            clear: 'both',
            background: 'rgba(0,0,0, 0.3)',
            borderRadius: 5,
            padding: 5,
            border: 'none',
            fontWeight: 'bold',
            color: WHITE,
            cursor: 'pointer',
            outline: 'none',
            display: 'none',
            left: '50%',
            '-webkit-transform': 'translateX(-50%)',
            transform: ' translateX(-50%)',
        },
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                '& .btn-quick-view': {
                    display: 'inline-block',
                },
            },
        },
    },
    imgProduct: {
        width: '100%',
        height: '100%',
    },
    detailItem: {
        height: 'auto',
        paddingTop: 14,
        position: 'relative',
    },
    descItem: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        position: 'relative',
        alignItems: 'center',
        padding: '0 6px',
    },
    productTitle: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        overflow: 'hidden',
        maxHeight: '38px',
        fontSize: '12px !important',
        fontWeight: 'bold !important',
        margin: '0px !important',
    },
    btnFeed: {
        position: 'absolute !important',
        top: '0',
        right: '4px',
        textAlign: 'right',
        padding: '0 !important',
        [theme.breakpoints.down('xs')]: {
            right: '0',
        },
    },
    productLinkButton: {
        maxWidth: 'calc(100% - 34px)',
    },
    iconFeed: {
        fontSize: 18,
        color: '#000000',
        fontWeight: '200',
    },
    iconActive: {
        color: PRIMARY,
    },
    colorContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 10px 0 0',
    },
    btnColor: {
        margin: '0px 5px 0px 0px',
    },
    feedContainer: {
        position: 'absolute',
        width: '20px',
        top: '-4px',
        right: '45px',
        textAlign: 'right',
    },
    btnAddToCart: {
        width: '100%', alignItems: 'center', paddingTop: 20, bottom: 0,
    },
    itemConfigurable: {
        width: '30px !important',
        height: '30px !important',
    },
    centerPosition: {
        alignItems: 'center',
    },
    divStar: {
        backgroundColor: '#E7E7EE',
        borderRadius: 666,
        padding: '0 24px',
        textTransform: 'uppercase',
        margin: '0px 0px 10px 0px',
    },
    divOrder: {
        padding: '0 12px 12px 12px',
    },
    freeOngkirLabel: {
        margin: '0px 0px 12px 0px',
        background: '#FF6A00',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
    },
    truckIcon: {
        width: '20px',
        height: 'auto',
    },
    freeOngkirText: {
        marginLeft: '8px',
        textTransform: 'uppercase',
        fontSize: '8px',
        color: WHITE,
    },
    noPaddingLeft: {
        paddingLeft: '0 !important',
    },
    upperTitle: {
        width: '100%',
        position: 'relative',
        padding: '0 24px 0 6px',
        [theme.breakpoints.down('xs')]: {
            padding: '0 18px 0 0',
        },
    },
    upperTitleNoWishlist: {
        width: '100%',
        padding: '0 6px',
        [theme.breakpoints.down('xs')]: {
            padding: '0',
        },
    },
}));
