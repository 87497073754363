import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY } from '@theme_color';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    dotsInside: {
        display: 'flex',
        zIndex: 2,
        flexDirection: 'row',
        position: 'absolute',
        justifyContent: 'space-around',
        bottom: 5,
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    dotsOutside: {
        marginTop: '10px',
        display: 'flex',
        zIndex: 2,
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            marginTop: '18px',
        },
    },
    dotsItem: {
        width: 10,
        height: 10,
        borderRadius: 100,
        backgroundColor: WHITE,
        margin: 5,
        cursor: 'pointer',
    },
    dotActive: {
        backgroundColor: PRIMARY,
        width: 10,
        height: 10,
    },
    hide: {
        display: 'none',
    },
    imageSlider: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    imageSliderAuto: {
        [theme.breakpoints.up('sm')]: {
            width: 'auto !important',
        },
    },
    thumborContainer: {
        width: '100%',
        position: 'relative',
        // paddingTop: '116%',
        // [theme.breakpoints.down('sm')]: {
        //     height: '100%',
        //     // backgroundColor: '#fff',
        // },
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
        },
    },
    thumborImage: {
        width: '100% !important',
        height: 'auto !important',
        // position: 'absolute',
        top: '0',
        left: '0',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            position: 'unset',
        },
    },
    arrow: {
        fontSize: '1.5rem',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: 5,
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(50% - 1rem)',
        width: 40,
        height: 40,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
    },
    leftArrow: {
        left: 20,
    },

    rightArrow: {
        right: 20,
    },
}));

export default useStyles;
