import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    container: {
        borderRadius: 8,
        margin: '5px 10px 5px 0',
        padding: '3px 9px',
        cursor: 'pointer',
    },
    hoverDisabled: {
        cursor: 'not-allowed',
    },
    bordered: {
        border: '3px solid #FF6A00 !important',
    },
    borderedSecondary: {
        border: '3px solid #F2F2F2',
    },
    notBordered: {
        background: '#F2F2F2',
    },
    disabledBox: {
        width: '6px',
        height: '100%',
        borderLeft: '2px solid #fff',
        borderRight: '2px solid #fff',
        background: 'red',
        margin: '0 auto',
        transform: 'rotate(45deg)',
    },
    label: {
        fontSize: 12,
        color: '#1A1818',
    },
    labelActive: {
        color: '#FF6A00',
    },
    labelDisabled: {
        color: '#D5D5D5',
    },
}));
