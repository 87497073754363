import { makeStyles } from '@material-ui/core/styles';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';
import {
    CreatePadding, FlexRow, FlexColumn,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        ...FlexColumn,
        position: 'relative',
        paddingTop: 18,
    },
    content: {
        ...FlexRow,
        ...CreatePadding(0, 0, 70, 0),
        flexWrap: 'wrap',
    },
    colorPrimary: {
        color: PRIMARY,
    },
    appBar: {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        flexGrow: 1,
    },
    pageTitle: {
        fontWeight: 700,
        textAlign: 'center',
        color: PRIMARY,
        textTransform: 'uppercase',
        position: 'absolute',
        left: '50px',
        right: '50px',
    },
    wishlistWrapper: {
        // paddingTop: "50px"
    },
    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-arround',
        position: 'fixed',
        bottom: 0,
        left: 0,
        background: 'rgba(255,255,255,0.7)',
        ...CreatePadding(20, 20, 20, 20),
        // textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            position: 'unset',
        },
    },
    wishlistItems: {
        flex: '0 0 25%',
        padding: '5px',
    },
    btnWishlist: {
        height: '50px',
        background: '#FF6A00 0% 0% no-repeat padding-box',
        borderRadius: 6,
        boxShadow: 'none',
        width: 'fit-content',
        '&:hover': {
            background: '#FF6A00 0% 0% no-repeat padding-box',
        },
    },
    btnShare: {
        background: '#fff',
        height: '50px',
        color: '#FF6A00',
        border: '2px solid #FF6A00',
        borderRadius: 6,
        boxShadow: 'none',
        width: 'fit-content',
        '&:hover': {
            border: '2px solid #FF6A00',
        },
    },
    textOrange: {
        color: '#FF6A00',
    },
    textAll: {
        fontSize: '0.938rem',
        [theme.breakpoints.down(768)]: {
            fontSize: '0.638rem',
        },
        [theme.breakpoints.down(400)]: {
            fontSize: '0.538rem',
        },
    },
    containerSkeleton: {
        marginTop: 24,
    },
    containerSkeletonMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginTop: '30%',
    },
    textRight: {
        textAlign: 'right',
    },
    textLeft: {
        textAlign: 'left',
    },
}));

export default useStyles;
