import { withTranslation } from '@i18n';
import { withApollo } from '@lib_apollo';
import dynamic from 'next/dynamic';
import { modules } from '@config';

const Home = dynamic(() => import('./main'));
const Checkout = dynamic(() => import('@module_checkout/pages/default'));

const Page = (props) => {
    if (!modules.checkout.checkoutOnly) return <Home {...props} />;

    return <Checkout {...props} />;
};

Page.getInitialProps = async () => ({
    namespacesRequired: modules.checkout.checkoutOnly ? ['common', 'checkout', 'customer', 'validate'] : ['common', 'home'],
});

export default withApollo({ ssr: true })(withTranslation()(Page));
