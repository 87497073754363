/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useTranslation } from '@i18n';
import { domToReact } from 'html-react-parser';
import { useApolloClient } from '@apollo/client';
import { localTotalCart } from '@services/graphql/schema/local';
import {
    addProductsToTempCart,
    getCustomerCartId,
    updateTempCartItem as mutationUpdateTempCartItem,
    getGuestTempCartId as queryGetGuestTempCartId,
    getCustomerTempCartId,
} from '@core_modules/cms/services/graphql';
import { getProductBySkuQuery, getTempCartItem } from '@core_modules/cms/services/graphql/schema';
import {
    getCartId,
    setCartId,
    getTempCartId,
    setTempCartId,
} from '@helper_cartid';
import { getLoginInfo } from '@helper_auth';
import { getHostUrl } from './helpers/getEnv';

const WidgetFireworkEmbedded = (props) => {
    const { fireworkContent, domNode } = props;
    const { t } = useTranslation(['common', 'product']);

    const client = useApolloClient();

    const [addCartProduct] = addProductsToTempCart();
    const [getGuestTempCartId] = queryGetGuestTempCartId();
    const [actUpdateItemTempCart] = mutationUpdateTempCartItem();

    let cartId = '';
    let tempCartId = '';
    let isLogin = '';
    const cartUser = getCustomerCartId();
    const tempCartUser = getCustomerTempCartId();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            cartId = getCartId();
            tempCartId = getTempCartId();
            isLogin = getLoginInfo();
        }
    }, []);

    const createFWProduct = (remoteProduct) => {
        const {
            sku, name, url_key, variants,
        } = remoteProduct;
        return window._fwn.shopping.productFactory((product) => {
            product
                .extId(sku)
                .name(name);

            if (variants && variants.length > 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const remoteVariant of variants) {
                    product.variant((variant) => {
                        variant
                            .extId(remoteVariant.product.sku)
                            .name(remoteVariant.product.name)
                            .url(`${getHostUrl()}/${url_key}`);
                    });
                }
            } else {
                product.variant((variant) => {
                    variant
                        .extId(sku)
                        .name(name)
                        .url(`${getHostUrl()}/${url_key}`);
                });
            }
        });
    };

    const addToCart = async (event) => {
        if (!cartId || cartId === '' || cartId === undefined || !tempCartId || tempCartId === '' || tempCartId === undefined) {
            if (!isLogin) {
                // temp cart
                await getGuestTempCartId()
                    .then((res) => {
                        const token = res.data.createEmptyTempCart;
                        tempCartId = token;
                        setTempCartId(token);
                    })
                    .catch((error) => {
                        window.toastMessage({
                            variant: 'error',
                            text: error?.message?.split(':')[1] ?? t('product:failedAddCart'),
                            open: true,
                        });
                    });
                // end temp cart
            } else if (cartUser.data && cartUser.data.customerCart && tempCartUser.data && tempCartUser.data.customerTempCart) {
                // magento cart
                const cartToken = cartUser.data.customerCart.id || '';
                cartId = cartToken;
                setCartId(cartToken);
                // end magento cart

                // temp cart
                const tempCartToken = tempCartUser.data.customerTempCart.id || '';
                tempCartId = tempCartToken;
                setTempCartId(tempCartToken);
                // end temp cart
            }
        }

        try {
            const resAddCartProduct = await addCartProduct({
                variables: {
                    cartId: tempCartId,
                    cartItems: [{
                        quantity: 1,
                        sku: event.detail.product_unit.unit_ext_id,
                        isFireworks: true,
                    }],
                },
            });

            if (!isLogin && (!cartId || cartId === '' || cartId === undefined)) {
                const cartToken = resAddCartProduct.data.addProductsToTempCart.tempCart.active_quote_id;
                cartId = cartToken;
                setCartId(cartToken);
            }
            client.writeQuery({
                query: localTotalCart,
                data: { totalCart: resAddCartProduct.data.addProductsToTempCart.tempCart.total_quantity },
            });

            window.toastMessage({
                variant: 'success',
                text: t('product:successAddCart'),
                open: true,
            });
        } catch (error) {
            const errorMessage = error?.message?.split(':')[1] ?? t('product:failedAddCart');
            window.toastMessage({
                variant: 'error',
                text: errorMessage,
                open: true,
            });

            throw new Error(errorMessage);
        }
    };

    // const requestCheckout = (event) => {
    //     console.log(event);
    // };

    const updateTempCartItem = async (product) => {
        if (!tempCartId || tempCartId === '' || tempCartId === undefined) {
            return product.quantity;
        }

        const resTempCartItem = await client.query({
            query: getTempCartItem,
            variables: { cartId: tempCartId },
            fetchPolicy: 'no-cache',
            context: {
                request: 'internal',
            },
        });

        let selectedCartItem = [];
        // get selected product from cart
        selectedCartItem = resTempCartItem.data?.tempCart?.items.filter((item) => item.product.sku === product.productUnit.unit_ext_id);

        if (
            selectedCartItem
            && selectedCartItem.length > 0
            && (selectedCartItem[0].quantity !== product.quantity)
        ) {
            try {
                const resUpdateTempCart = await actUpdateItemTempCart({
                    variables: {
                        cartId: tempCartId,
                        cartItems: [{
                            cart_item_id: parseInt(selectedCartItem[0].id, 0),
                            quantity: parseInt(product.quantity, 0),
                        }],
                    },
                });

                client.writeQuery({ query: localTotalCart, data: { totalCart: resUpdateTempCart.data.updateTempCartItems.tempCart.total_quantity } });

                window.toastMessage({
                    variant: 'success',
                    text: t('common:cart:updateSuccess'),
                    open: true,
                });
            } catch (error) {
                const errorMessage = error?.message?.split(':')[1] ?? t('common:cart:updateFailed');
                window.toastMessage({
                    variant: 'error',
                    text: errorMessage,
                    open: true,
                });

                throw new Error(errorMessage);
            }
        }

        return product.quantity;
    };

    const parseProduct = (remoteProduct) => {
        const stock_status = remoteProduct.stock_status.toLowerCase() === 'in_stock';
        return window._fwn.shopping.productFactory((builder) => {
            builder
                .extId(remoteProduct.product.sku)
                .name(remoteProduct.product.name)
                .currency(remoteProduct.prices.price.currency);

            builder.variant((variantBuilder) => {
                variantBuilder
                    .extId(remoteProduct.product.sku)
                    .isAvailable(stock_status)
                    .name(remoteProduct.product.name)
                    .price(remoteProduct.prices.price.value)
                    .sku(remoteProduct.product.sku)
                    .url(`${getHostUrl()}/${remoteProduct.product.url_key}`)
                    .image((imageBuilder) => {
                        imageBuilder
                            .extId(remoteProduct.product.sku)
                            .position(0)
                            .title(remoteProduct.product.name)
                            .url(remoteProduct.product.image);
                    });
                if (remoteProduct.additional_info && remoteProduct.additional_info.length > 0) {
                    let configurableName = '';

                    remoteProduct.additional_info.forEach((info) => {
                        configurableName += `-${info.value}`;
                    });

                    variantBuilder.name(remoteProduct.product.name + configurableName);
                }
            });
        }, true);
    };

    const fwReady = () => {
        // configure cart
        window._fwn.shopping.configureCart({
            url: `${getHostUrl()}/checkout/cart`,
            currency: 'IDR',
        });

        // on cart display (sync cart)
        window._fwn.shopping.onCartDisplayed(async () => {
            if (!tempCartId || tempCartId === '' || tempCartId === undefined) {
                return [];
            }

            const resTempCartItem = await client.query({
                query: getTempCartItem,
                variables: { cartId: tempCartId },
                fetchPolicy: 'no-cache',
                context: {
                    request: 'internal',
                },
            });

            const remoteTempCartItem = resTempCartItem.data.tempCart.items.map((tempCartItem) => ({
                product: parseProduct(tempCartItem),
                // cart_item_sku is child's sku (if configurable)
                unitId: tempCartItem.product.sku,
                quantity: tempCartItem.quantity,
            }));

            return remoteTempCartItem;
        });

        // on cart update
        window._fwn.shopping.onCartUpdated(async (product) => {
            const resUpdateTempCart = await updateTempCartItem(product);
            return resUpdateTempCart;
        });

        // on product loaded to edit product data
        window._fwn.shopping.onProductsLoaded(async ({ products }) => {
            const bulkProductSku = products.map((product) => product.product_ext_id);

            const getProductBySku = await client.query({
                query: getProductBySkuQuery,
                variables: {
                    bulkSku: bulkProductSku,
                },
                fetchPolicy: 'no-cache',
            });

            const { data: { customProductSearch: remoteProducts } } = await getProductBySku;

            return remoteProducts.map((remoteProduct) => createFWProduct(remoteProduct));
        });
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // window._fwn.shopping is available within the callback
            document.addEventListener('fw:ready', fwReady);

            // add to cart function
            document.addEventListener('fw:shopping:pdp-primary-cta-clicked', addToCart);

            // check out function
            // document.addEventListener('fw:shopping:request-checkout', requestCheckout);
        }

        // cleanup
        return () => {
            document.removeEventListener('fw:ready', fwReady);
            document.removeEventListener('fw:shopping:pdp-primary-cta-clicked', addToCart);
            // document.removeEventListener('fw:shopping:request-checkout', requestCheckout);
        };
    }, []);

    return (
        <>
            <Head>
                <script async src="//asset.fwcdn3.com/js/fwn.js" type="text/javascript" />
            </Head>
            {domToReact(fireworkContent, domNode)}
        </>
    );
};

export default WidgetFireworkEmbedded;
