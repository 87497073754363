import React from 'react';
import dynamic from 'next/dynamic';
import useStyles from '@core_modules/cms/components/cms-renderer/widget-multiple-slider/style';

const BannerSliderSkeleton = dynamic(() => import('@core_modules/home/pages/default/components/Skeleton/BannerSkeleton'));
const MultiBannerView = dynamic(() => import('@core_modules/home/pages/default/components/Banner/multisliderView'));
const BannerSlider = dynamic(() => import('@core_modules/home/pages/default/components/Banner'));
const ErrorInfo = dynamic(() => import('@core_modules/home/pages/default/components/ErrorInfo'), { ssr: false });

const WidgetMultipleSliders = (props) => {
    const {
        slider_id,
        ...other
    } = props;
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <BannerSlider
                slider_id={slider_id}
                BannerSliderSkeleton={BannerSliderSkeleton}
                BannerView={MultiBannerView}
                ErrorInfo={ErrorInfo}
                {...other}
            />
        </div>
    );
};

export default WidgetMultipleSliders;
