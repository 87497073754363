/* eslint-disable max-len */
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Router from 'next/router';
import useStyles from '@core_modules/theme/components/bottom-navigation/mobile/style';
import ShoppingBagIcon from '@core_modules/cart/plugin/ShoppingBag';

const Navigator = (props) => {
    const styles = useStyles();
    const {
        active, handleOpenModal,
    } = props;
    return (
        <>
            <BottomNavigation
                className={styles.navigation}
                value={active}
                showLabels={false}
                onChange={(event, newValue) => {
                    switch (newValue) {
                    case 'home':
                        Router.push('/');
                        return;
                    case 'browse':
                        handleOpenModal(true);
                        return;
                    case 'cart':
                        Router.push('/checkout/cart');
                        return;
                    case 'account':
                        Router.push('/customer/account');
                        break;
                    default:
                    }
                }}
            >
                <BottomNavigationAction
                    label="Home"
                    value="home"
                    icon={(
                        <svg id="Home" xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17">
                            <path id="Path_81" data-name="Path 81" d="M145.359,148.3l9.321,9.4a.255.255,0,0,1-.195.4h-2.366a.2.2,0,0,0-.195.2v6.739a.2.2,0,0,1-.195.2h-4.2a.2.2,0,0,1-.195-.2v-5.039a.2.2,0,0,0-.195-.2h-3.81a.2.2,0,0,0-.195.2v5.039a.21.21,0,0,1-.195.2H138.68a.2.2,0,0,1-.195-.2v-6.739a.2.2,0,0,0-.195-.2H136a.239.239,0,0,1-.195-.4l9.25-9.4A.237.237,0,0,1,145.359,148.3Z" transform="translate(-135.734 -148.247)" fillRule="evenodd" />
                        </svg>
                    )}
                    classes={{
                        label: 'hide',
                        root: styles.navAction,
                        selected: styles.navActionSelected,
                    }}
                />
                <BottomNavigationAction
                    label="Browse"
                    value="browse"
                    icon={(
                        <svg id="Component_23_1" xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
                            <g id="Group_19" data-name="Group 19" transform="translate(1 1)">
                                <path id="Line_6" data-name="Line 6" d="M12,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H12a1,1,0,0,1,1,1A1,1,0,0,1,12,1Z" transform="translate(0 0)" />
                                <path id="Line_7" data-name="Line 7" d="M12,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H12a1,1,0,0,1,1,1A1,1,0,0,1,12,1Z" transform="translate(0 7)" />
                                <path id="Line_8" data-name="Line 8" d="M12,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H12a1,1,0,0,1,1,1A1,1,0,0,1,12,1Z" transform="translate(0 14)" />
                            </g>
                            <g id="Group_529" data-name="Group 529" transform="translate(17 1)">
                                <path id="Line_6-2" data-name="Line 6" d="M1,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H1A1,1,0,0,1,2,0,1,1,0,0,1,1,1Z" transform="translate(0 0)" />
                                <path id="Line_7-2" data-name="Line 7" d="M1,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H1A1,1,0,0,1,2,0,1,1,0,0,1,1,1Z" transform="translate(0 7)" />
                                <path id="Line_8-2" data-name="Line 8" d="M1,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H1A1,1,0,0,1,2,0,1,1,0,0,1,1,1Z" transform="translate(0 14)" />
                            </g>
                        </svg>
                    )}
                    classes={{
                        label: 'hide',
                        root: styles.navAction,
                        selected: styles.navActionSelected,
                    }}
                />
                <BottomNavigationAction
                    label="Cart"
                    value="cart"
                    icon={<ShoppingBagIcon />}
                    classes={{
                        label: 'hide',
                        root: styles.navAction,
                        selected: styles.navActionSelected,
                    }}
                />
                <BottomNavigationAction
                    label="Account"
                    value="account"
                    icon={(
                        <svg id="user" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10" />
                            <g id="Group_37" data-name="Group 37" transform="translate(4.42 3.135)">
                                <path id="Path_37" data-name="Path 37" d="M857.875,91.17h-5.32a2.012,2.012,0,0,1,.833-2.868,8.344,8.344,0,0,1,4.487-1.008c1.16-.012,4.726.66,5.074,1.921a2.912,2.912,0,0,1,0,1.956Z" transform="translate(-852.252 -79.16)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="2.836" cy="2.836" r="2.836" transform="translate(2.59)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            </g>
                        </svg>

                    )}
                    classes={{
                        label: 'hide',
                        root: styles.navAction,
                        selected: styles.navActionSelected,
                    }}
                />
            </BottomNavigation>
        </>
    );
};

export default Navigator;
